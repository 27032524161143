import initAxios from "./defaultClient";

const prefix = "/rewards";

export const Getrewards = async (args) => {
  const axios = initAxios();
  return await axios.get(prefix + "/", {
    params: args,
  });
};

export const Postrewards = async (args) => {
  const axios = initAxios();
  return await axios.post(prefix + "/", {});
};
