import axios from "axios";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const initAxios = () => {
  axios.defaults.withCredentials = true;

  const params = new URLSearchParams(window.location.search);
  let userToken = params.get("userToken");

  const localToken = userToken ? userToken : localStorage.getItem("user_token");

  console.log("localToken", localToken);

  if (localToken) {
    axios.defaults.headers.common = { Authorization: `Bearer ${localToken}` };
  }
  const namespace = "/ep/v1/esg";
  const baseURL = (() => {
    return `https://wp.daitso.kbds.co.kr/wp-json${namespace}`;
  })();

  const defaultClient = axios.create({
    baseURL,
    withCredentials: true,
  });

  return defaultClient;
};
export default initAxios;
