import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  .wrapper {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
  }
  .top {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.03) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  .bg {
    height: 260px;
    background-color: #eee;
    border-radius: 10px;
    margin-bottom: 24px;
  }
  .auth-null {
    text-align: center;
    img {
      width: calc(100% + 40px);
      height: auto;
      margin-top: 16px;
      /* transform: translateX(20px); */
    }
    .pg {
      width: 80px;
      height: auto;
      padding-left: 40px;
    }
  }
  .tab-menu {
    padding: 0 20px;
    display: flex;
    align-items: center;
    gap: 40px;
    max-width: 500px;
    width: 100%;
    /* position: fixed; */
    /* left: 50%; */
    /* transform: translateX(-50%); */
    z-index: 1232;
    /* top: 73px; */

    li {
      color: #9b9b9b;
      font-family: Noto Sans KR;
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.7px;
      border-bottom: 2px solid transparent;
      padding-bottom: 10px;
      cursor: pointer;
      display: flex;
      gap: 3px;
      align-items: center;
      &.active {
        color: #000;
        border-bottom: 2px solid #000;
      }
      &:hover {
        opacity: 0.8;
      }
      &.active span {
        border-radius: 30px;
        background: #000;
        width: 24px;
        height: 20px;
        display: inline-block;
        color: #fff;
        font-family: Noto Sans KR;
        font-size: 11px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.55px;
        text-align: center;
      }
    }
  }
  a {
    text-decoration: none;
    color: #000;
  }
  .main-wrapper {
    padding-top: 20px;
    overflow: hidden;

    .flex {
      display: flex;
      justify-content: space-between;
      margin-bottom: 14px;
    }
    h3 {
      color: #000;
      font-family: Noto Sans KR;
      font-size: 20px;
      font-weight: 800;
      line-height: normal;
      letter-spacing: -0.4px;
      padding: 0 20px;
    }
    .title {
      font-size: 20px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.4px;
      padding: 0 20px;
      span {
        color: #ffbc00;
      }
    }
  }

  .post-wrapper {
    margin-top: 16px;
    max-width: calc(100% - 40px);
    /* transform: translateX(-20px); */
    margin: 0 auto;
    overflow: visible;
    position: relative;

    .swiper {
      overflow: visible !important;
    }
  }
  .card-img {
    background-image: url(/images/esg01.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    border-radius: 10px 10px 0 0;
  }
  .esg-card {
    /* width: 260px !important; */
    ㄴ a {
      display: block;
    }
    position: relative;
    cursor: pointer;
    transition: all 0.3s;
    box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.05);
    /* &:after {
      position: absolute;
      content: "";
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.8) 100%
      );
      width: 100%;
      height: 136px;
      bottom: 0;
      border-radius: 0 0 10px 10px;
    } */
    &:hover {
      opacity: 0.8;
    }
    .top-menu {
      padding: 20px;
      color: #fff;
      position: relative;
      &:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 86px;
        background: linear-gradient(
          360deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.8) 100%
        );
        left: 0;
        top: 0;
        border-radius: 10px 10px 0 0;
      }
      .user-info {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .user-img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: 0.4px solid #e9e9e9;
        /* background-image: url(/images/esg-user.png); */
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
      .name {
        font-size: 13px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.26px;
      }
      .date {
        font-size: 11px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.22px;
        margin-top: 3px;
      }
    }
    .under-menu {
      color: #000;
      padding: 15px 20px 21px 20px;
      position: absolute;
      bottom: 0;
      width: 100%;
      min-height: 116px;
      z-index: 1;
      border-radius: 0 0 10px 10px;
      background-color: #fff;
      border: 1px solid #e0e0e0;
      .cate {
        display: flex;
        justify-content: space-between;
        .icon-bar {
          display: flex;
          gap: 14px;
          color: #666;
          font-size: 13px;
          font-weight: 500;
          line-height: 160%;
          img {
            margin-right: 6px;
          }
        }
      }
      .page-desc {
        font-size: 11px;
        font-weight: 700;
        line-height: 24px;
        display: inline-block;
        color: #000;
        width: auto;
        height: 23px;
        text-align: center;
        padding: 0 8px;
        border-radius: 30px;
        background: #cee082;
      }
      .page-desc.eco {
        background: #cee082;
      }
      .page-desc.health {
        background: #95c4ca;
      }
      .page-desc.culture {
        background: #e69377;
      }

      .title-box {
        display: flex;
        gap: 3px;
      }
      .page-title {
        font-size: 14px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.28px;
        margin-top: 8px;
      }
      .Participation {
        margin-top: 11px;
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 8px;
        .user-list-box {
          display: flex;
          .user-list {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-image: url(/images/esg-user.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            margin-left: -8px;
          }
        }
        .Participation-title {
          h2 {
            color: #ffbc00;
            font-size: 14px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.28px;
          }
          span {
            color: #000;
          }
        }
      }
    }
  }
  .swiper-pagination {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 16px;
    /* transform: translateX(20px); */
  }
  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #000000;
    opacity: 0.1;
    cursor: pointer;
  }
  .swiper-pagination-bullet-active {
    opacity: 1;
  }
  .challenge-box {
    margin-top: 30px;
    padding: 0 20px;
    .challenge-card {
      background-color: #fff5be;
      padding: 18px 0 0 16px;
      border-radius: 10px;
      margin-top: 10px;
      height: 80px;
      background-image: url(/images/challenge01.png);
      background-size: 105px;
      background-repeat: no-repeat;
      background-position: bottom 0 right 10px;
      transition: all 0.3s;
      &:hover {
        opacity: 0.8;
      }
      .challenge-title {
        font-size: 16px;
        font-weight: 700;
        line-height: 150%;
        letter-spacing: -0.32px;
      }
      .going {
        margin-top: 6px;
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
          font-size: 12px;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: -0.24px;
          margin-right: 4px;
        }
      }
    }
    .Colleague {
      background: #beddff;
      background-image: url(/images/challenge02.png);
      background-size: 115px;
      background-repeat: no-repeat;
      background-position: bottom 0 right 10px;
    }
    .family {
      background: #e7f4d3;
      background-image: url(/images/challenge03.png);
      background-size: 130px;
      background-repeat: no-repeat;
      background-position: bottom 0 right 10px;
    }
  }
  .bold-line {
    border-top: 8px solid #00000008;
    margin: 40px 0 45px 0;
  }
  .popular-box {
    padding: 0 20px;
    .popular-title {
      font-size: 20px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.4px;
    }
    .tab-menu {
      display: flex;
      align-items: center;
      width: 100vw;
      transform: translateX(-20px);
      gap: 40px;
      border-bottom: 1px solid #eee;
      margin: 0;
      margin-top: 20px;
      margin-bottom: 14px;

      li {
        color: #9b9b9b;
        font-family: Noto Sans KR;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.7px;
        border-bottom: 2px solid transparent;
        padding-bottom: 10px;
        cursor: pointer;
        transition: all 0.3s;
        &.active {
          color: #000;
          border-bottom: 2px solid #000;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .content-environment {
    margin-top: 10px;
    padding-bottom: 100px;
  }
  .challenge-content-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 0 10px;

    .challenge-content-box {
      width: calc((100% - 10px) / 2);
      margin-top: 10px;
    }
    .challenge-content {
      margin-bottom: 10px;
      position: relative;

      &:hover .dim {
        opacity: 1;
      }
      .dim {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.7);
        opacity: 0;
        transition: all 0.3s;
        p {
          color: #fff;
          font-size: 12px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.24px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .content-img {
        width: 100%;
        height: 0;
        padding-bottom: 78%;
        background-image: url(/images/popular-challenge01.png);
        border-radius: 10px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: relative;
        .noti {
          border-radius: 4px;
          background: rgba(0, 0, 0, 0.56);
          display: inline-block;
          color: #fff;
          font-size: 11px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.22px;
          padding: 4px 6px 5px 6px;
          position: absolute;
          top: 8px;
          right: 14px;
        }
      }
      .content-text-box {
        margin-top: 11px;
        .categories {
          color: #666;
          font-size: 12px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.24px;
        }
        .content-title {
          font-size: 14px;
          font-weight: 700;
          line-height: 160%;
          letter-spacing: -0.28px;
          margin-top: 3px;
        }
        .point-box {
          /* margin-top: 7px; */
          display: flex;
          align-items: center;
          gap: 4px;
          font-size: 12px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.24px;
          margin-top: 5px;
        }
        .date {
          color: #666;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: -0.24px;
          margin-top: 7px;
        }
      }
    }
  }
  .show-box {
    padding-bottom: 80px;
    .show-more {
      border-radius: 50px;
      border: 1px solid #e9e9e9;
      background: #fff;
      width: 112px;
      height: 42px;
      margin: 20px auto 40px auto;
      color: #000;
      font-size: 13px;
      font-weight: 500;
      line-height: 40px;
      letter-spacing: -0.26px;
      display: flex;
      align-items: center;
      gap: 6px;
      justify-content: center;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        opacity: 0.6;
      }
    }
  }
`;
