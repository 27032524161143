import styled from "styled-components";

export const EsgmypageWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  .wrapper {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
    padding-bottom: 100px;
  }
  a {
    text-decoration: none;
    color: #000;
  }
  .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 160%;
    letter-spacing: -0.36px;
    margin-top: 2px;
  }

  .my-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 90px 20px 0px 20px;
    .user-box {
      display: flex;
      gap: 20px;
    }
    .user-img {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      background-image: url(/images/esg-user.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    p {
      font-size: 16px;
      font-weight: 700;
      line-height: 160%;
      letter-spacing: -0.32px;
      margin-top: 3px;
    }
    span {
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.28px;
      display: flex;
      gap: 8px;
      font-family: Noto Sans KR;
      margin-top: 6px;
    }
  }
  .situation-wrap {
    display: flex;
    justify-content: center;
    max-width: 140px;
    width: 100%;
    border-radius: 10px;
    background: #f7f7f7;
    padding: 8px 0;
    gap: 0 18px;
    .situation-a {
      position: relative;
      display: block;
      padding: 0;
      &::after {
        position: absolute;
        top: 0;
        right: -11px;
        width: 1px;
        height: 42px;
        background-color: #e9e9e9;
        content: "";
      }
    }
    a {
      text-align: center;
      transition: all 0.3s;
      p {
        color: #666;
        font-size: 12px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.24px;
      }
      h5 {
        font-size: 15px;
        font-weight: 600;
        letter-spacing: -0.3px;
        margin-top: 8px;
      }
      &:hover {
        opacity: 0.6;
      }
    }
  }
  .achieve-wrap {
    margin-top: 30px;
    padding: 0 20px;
    .title-box {
      display: flex;
      gap: 15px;
      .Percentage {
        border-radius: 10px;
        background: #000;
        padding: 8px 10px 10px 6px;
        height: 28px;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        position: relative;
        &:after {
          position: absolute;
          content: "";
          top: 6px;
          left: -7px;
          background-image: url(/images/Triangle-left.svg);
          background-repeat: no-repeat;
          background-size: contain;
          width: 14px;
          height: 14px;
        }
      }
    }
    .graph {
      width: 100%;
      height: 10px;
      border-radius: 10px;
      background-size: cover;
      background-image: url("/images/bar-gray.svg");
      background-position: center;
      position: relative;
      margin-top: 16px;
      overflow: hidden;
      &:after {
        position: absolute;
        display: block;
        content: "";
        width: calc(${(props) => (props.ratio ? props.ratio : 0)}%);
        height: 100%;
        background-image: url("/images/bar-yellow.svg");
        background-size: cover;
        background-position: center;
        border-radius: 10px;
      }
    }
  }
  .esg-btn {
    margin-top: 16px;
    display: block;
    padding: 0 20px;
    transition: all 0.3s;
    &:hover {
      opacity: 0.6;
    }
    span {
      border-radius: 10px;
      background: #f7f7f7;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 10px 12px 0px;
      gap: 6px;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.28px;
    }
  }

  .bold-line {
    border-top: 8px solid #00000008;
    margin: 34px auto 30px auto;
  }
  .recommend-wrap {
    .re-title {
      padding: 0 20px;
    }
  }
  .swiper-wrapper {
    padding-bottom: 54px;
  }
  .recommend-wrapper {
    padding-left: 20px;
    margin-top: 16px;
  }
  .recommend-card {
    position: relative;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      opacity: 0.8;
    }

    .content-img {
      width: 100%;
      height: 0;
      padding-bottom: 65.3%;
      border-radius: 10px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      position: relative;
      border-radius: 10px;
    }
    .noti {
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.56);
      display: inline-block;
      color: #fff;
      font-size: 11px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.22px;
      padding: 4px 6px 5px 6px;
      position: absolute;
      top: 8px;
      right: 14px;
    }
    .content-text-box {
      margin-top: 10px;
      .categories {
        color: #666;
        font-size: 12px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.24px;
      }
      .content-title {
        font-size: 14px;
        font-weight: 700;
        line-height: 160%;
        letter-spacing: -0.28px;
      }
      .point-box {
        margin-top: 7px;
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 12px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.24px;
      }
    }
  }
  .number {
    margin-top: 6px;
    color: #666;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.24px;
  }
  .gauge-wrapper {
    padding: 0 20px;
    .select-wrap {
      margin-top: 16px;
      select {
        width: 100%;
        border-radius: 10px;
        border: 1px solid #e9e9e9;
        background: #fff;
        padding: 11px 20px 13px 20px;
        color: #000;
        font-family: Noto Sans KR;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.28px;
        appearance: none;
        -webkit-appearance: none;
        background-image: url(/images/esg-select-arrow.svg);
        background-repeat: no-repeat;
        background-position: top 50% right 20px;
      }
    }
    .btn-wrap {
      border-bottom: 1px solid #eeeeee;
      padding: 0 30px;
      .btn-list {
        border-radius: 10px;
        display: flex;
        height: 46px;
        color: #666;
        font-size: 14px;
        font-weight: 700;
        line-height: 44px;
        .accomplishment,
        .participation {
          width: 50%;
          cursor: pointer;
          text-align: center;
          transition: all 0.3s;
          &.active {
            color: #000;
            border-bottom: 2px solid #000;
          }
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
    .earth-wrap {
      margin-top: 52px;
      padding: 0 10px;
      .noti {
        border-radius: 20px 20px 2px 20px;
        background: #000;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: -0.36px;
        display: inline-block;
        height: 48px;
        padding: 0 18px;
        line-height: 48px;
      }
      .img-box {
        max-width: 250px;
        width: 100%;
        height: 300px;
        margin: 0 auto;
        position: relative;
        top: -60px;
        left: 11px;
        .earth {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      p {
        border-radius: 16px;
        background: #000;
        color: #fff;
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        max-width: 254px;
        width: 100%;
        margin: 0 auto;
        top: -45px;
        position: relative;
        height: 44px;
        line-height: 44px;
        &:after {
          position: absolute;
          content: "";
          background-image: url(/images/Triangle-top.svg);
          width: 12px;
          height: 10px;
          top: -9px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .img-box2 {
        max-width: 250px;
        width: 100%;
        height: 300px;
        margin: 0 auto;
        position: relative;
        /* top: -90px; */

        .earth {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      p {
        border-radius: 16px;
        background: #000;
        color: #fff;
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        max-width: 254px;
        width: 100%;
        margin: 0 auto;
        top: -44px;
        position: relative;
        height: 44px;
        line-height: 44px;
        &:after {
          position: absolute;
          content: "";
          background-image: url(/images/Triangle-top.svg);
          width: 12px;
          height: 10px;
          top: -9px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .f-text {
        top: -80px;
      }
      .l-text {
        top: -28px;
      }
      .e-text {
        top: -34px;
      }
    }

    .participation-content {
      margin-top: 8px;
      .total {
        font-size: 12px;
        font-weight: 700;
        letter-spacing: -0.24px;
        font-family: Noto Sans KR;
        margin-top: 16px;
      }
      .challenge-content-wrap {
        display: flex;
        flex-wrap: wrap;
        gap: 0 10px;

        .challenge-content-box {
          width: calc((100% - 10px) / 2);
          margin-top: 10px;
        }
        .challenge-content {
          margin-bottom: 10px;
          position: relative;

          &:hover .dim {
            opacity: 1;
          }
          .dim {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            background: rgba(0, 0, 0, 0.7);
            opacity: 0;
            transition: all 0.3s;
            p {
              color: #fff;
              font-size: 12px;
              font-weight: 500;
              line-height: normal;

              letter-spacing: -0.24px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
          .content-img {
            width: 100%;
            height: 0;
            padding-bottom: 78%;
            background-image: url(/images/popular-challenge01.png);
            border-radius: 10px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            position: relative;
            .noti {
              border-radius: 4px;
              background: rgba(0, 0, 0, 0.56);
              display: inline-block;
              color: #fff;
              font-size: 11px;
              font-weight: 500;
              line-height: normal;
              letter-spacing: -0.22px;
              padding: 4px 6px 5px 6px;
              position: absolute;
              top: 8px;
              right: 14px;
            }
          }
          .content-text-box {
            margin-top: 11px;
            .categories {
              color: #666;
              font-size: 12px;
              font-weight: 500;
              line-height: normal;
              letter-spacing: -0.24px;
            }
            .content-title {
              font-size: 14px;
              font-weight: 700;
              line-height: 160%;
              letter-spacing: -0.28px;
              margin-top: 3px;
            }
            .point-box {
              /* margin-top: 7px; */
              display: flex;
              align-items: center;
              gap: 4px;
              font-size: 12px;
              font-weight: 500;
              line-height: normal;
              letter-spacing: -0.24px;
              margin-top: 5px;
            }
            .date {
              color: #666;
              font-size: 12px;
              font-weight: 500;
              letter-spacing: -0.24px;
              margin-top: 7px;
            }
          }
        }
      }
      .show-box {
        padding-bottom: 10px;
        .show-more {
          border-radius: 50px;
          border: 1px solid #e9e9e9;
          background: #fff;
          width: 112px;
          height: 42px;
          margin: 20px auto 40px auto;
          color: #000;
          font-size: 13px;
          font-weight: 500;
          line-height: 40px;
          letter-spacing: -0.26px;
          display: flex;
          align-items: center;
          gap: 6px;
          justify-content: center;
          cursor: pointer;
          transition: all 0.3s;
          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }
  @media ${(props) => props.theme.mobile} {
  }
`;
