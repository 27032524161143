import React from "react";
import { LoadingWrapper } from "./styled";

const Loading = () => {
  return (
    <LoadingWrapper>
      <div class="loading circle">
        <div />
      </div>
    </LoadingWrapper>
  );
};

export default Loading;
