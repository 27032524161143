import initAxios from "./defaultClient";

const prefix = "/challenges";

export const GetChallenges = async (args) => {
  const axios = initAxios();
  return await axios.get(prefix + "/", {
    params: args,
  });
};

export const GetJoins = async (args) => {
  const axios = initAxios();
  return await axios.get(prefix + "/join", {
    params: args,
  });
};

export const GetJoinsAll = async (args) => {
  const axios = initAxios();
  return await axios.get(prefix + "/join/total", {
    params: args,
  });
};
export const GetJoin = async (args) => {
  const axios = initAxios();
  return await axios.get(prefix + "/join/" + args.joinId, {
    params: args,
  });
};

export const GetChallenge = async (args) => {
  const axios = initAxios();
  return await axios.get(
    prefix + "/" + (args.challengeId ? args.challengeId : args.joinId),
    {
      params: args,
    }
  );
};

export const ApplyChallenge = async (args) => {
  const axios = initAxios();
  return await axios.post(prefix + "/" + args.challengeId, {});
};

export const unSubscribeChallenge = async (args) => {
  const axios = initAxios();
  return await axios.post(prefix + "/cancel/" + args.challengeId, {});
};

export const insertAuth = async (args) => {
  const axios = initAxios();
  return await axios.post(prefix + "/auth", args, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const GetAuthIsMine = async (args) => {
  const axios = initAxios();
  return await axios.get(prefix + "/auth/isMine/", {
    params: args,
  });
};

export const GetAuthAll = async (args) => {
  const axios = initAxios();
  return await axios.get(prefix + "/authes/", {
    params: args,
  });
};

export const GetAuthByChallenge = async (args) => {
  const axios = initAxios();
  return await axios.get(prefix + "/auth/" + args.challengeId, {
    params: args,
  });
};

export const GetAuthSingle = async (args) => {
  const axios = initAxios();
  return await axios.get(prefix + "/auth/single/" + args.authId, {
    params: args,
  });
};

export const InsertLike = async (args) => {
  const axios = initAxios();
  return await axios.post(prefix + "/auth/like/" + args.authId, args);
};

export const DeleteAuth = async (args) => {
  const axios = initAxios();
  return await axios.delete(prefix + "/auth/", {
    params: args,
  });
};

export const GetChallengeNum = async (args) => {
  const axios = initAxios();
  return await axios.get(prefix + "/total/", {
    params: args,
  });
};

export const GetChallengesNum = async (args) => {
  const axios = initAxios();
  return await axios.get(prefix + "/num/", {
    params: args,
  });
};

export const GetAuthesNum = async (args) => {
  const axios = initAxios();
  return await axios.get(prefix + "/auth/total/", {
    params: args,
  });
};
