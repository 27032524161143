import React, { useEffect, useState } from "react";
import { EsgmypageWrapper } from "./styled";
import Layout from "../../componet/Layout";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { useRecoilState, useSetRecoilState } from "recoil";
import { userData } from "../../store/users";
import { GetMeEsg } from "../../service/me";
import { GetChallenges, GetJoins } from "../../service/challeng";
import { GetJoinsAll } from "../../service/challeng";
import { useNavigate } from "react-router-dom";
import { loadingAtom } from "../../store/loading";

const Mypage = () => {
  //
  const [user, setUser] = useRecoilState(userData);
  const [challenges, setChallenges] = useState([]);
  const [total, setTotal] = useState(0);
  const nav = useNavigate();
  const setRecoilLoading = useSetRecoilState(loadingAtom);

  // useEffect(() => {
  //   GetChallenges({
  //     paged: 1,
  //     postsPerPage: 6,
  //   }).then((res) => {
  //     console.log(res);
  //     if (res.data.code === "200") {
  //       setChallenges(res.data.body.items);
  //       setTotal(res.data.body.total);
  //     } else {
  //       setChallenges(null);
  //       setTotal(0);
  //     }
  //   });
  // }, []);

  // 게이지

  const [activeButton, setActiveButton] = useState("accomplishment");

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };
  const [year, setYear] = useState("2024");
  const [rate, setRate] = useState(-1);
  const [successNum, setSuccessNum] = useState(0);

  useEffect(() => {
    GetMeEsg({
      year: year,
    })
      .then((res) => {
        if (res.data.code == "200") {
          console.log(res);
          setRate(res.data.body.rate);
          setSuccessNum(res.data.body.successNum);
          if (res.data.body.rate > 100) {
            setRate(100);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [year]);

  // useEffect(() => {
  //   setRecoilLoading(true);
  //   GetJoinsAll({
  //     year,
  //     postsPerPage: 100,
  //   })
  //     .then((res) => {
  //       if (res.data.code == "200") {
  //         setChallenges(res.data.body.items);
  //         setTotal(res.data.body.total);
  //       }
  //       setRecoilLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setRecoilLoading(false);
  //     });
  // }, [year]);

  const [maxPaged, setMaxPaged] = useState(1);
  const [subSlug, setSubSlug] = useState("");

  useEffect(() => {
    setRecoilLoading(true);
    GetJoins({
      paged: 1,
      postsPerPage: "-1",
      // subSlug: subSlug,
    }).then((res) => {
      console.log(res);
      if (res.data.code === "200") {
        setChallenges(res.data.body.items);
        setTotal(res.data.body.total);
        setMaxPaged(res.data.body.maxPaged);
      } else {
        setChallenges(null);
        setTotal(0);
      }
      setRecoilLoading(false);
    });
  }, [subSlug]);

  const [hasContent] = useState(true);

  return (
    <>
      <Layout headerType={"sub"} headerTitle={"마이페이지"} footerType={"my"}>
        <EsgmypageWrapper
          ratio={
            (user?.successNum / 52) * 100 > 100
              ? 100
              : (user?.successNum / 52) * 100
          }
        >
          <div className="wrapper">
            <div className="my-info">
              <div className="user-box">
                <div
                  className="user-img"
                  style={{ backgroundImage: `url(${user?.avatar})` }}
                ></div>

                <div>
                  <p>{user?.name}</p>
                  <span>
                    <img src="/images/esg-point.svg" alt="" />
                    {user?.point}
                  </span>
                </div>
              </div>
              <div className="situation-wrap">
                <a href="/mypageauthlist" className="situation-a">
                  <p>완주횟수</p>
                  <h5>{user?.successNum}</h5>
                  {/* <p>참여중</p>
                  <h5>{user?.joinNum}</h5> */}
                </a>
                <a href="/mypage/authlist">
                  <p>인증 내역</p>
                  <h5>{user?.authNum}</h5>
                </a>
              </div>
            </div>

            <div className="achieve-wrap">
              <div className="title-box">
                {/* <h2 className="title">월 평균 달성률</h2>
                <div className="Percentage">📍 {user?.rate}%</div> */}
                <h2 className="title">권장 챌린지 지수</h2>
                <div className="Percentage">
                  📍 {user?.successNum ? user?.successNum : 0} / 52
                </div>
              </div>
              <div className="graph"></div>
            </div>
            {/* <a href="/gauge" className="esg-btn">
              <span>
                <img src="/images/gauge-icon.svg" alt="" />내 ESG 게이지
              </span>
            </a> */}

            <div className="bold-line"></div>
            {/* <div className="recommend-wrap">
              <h2 className="title re-title">이런 챌린지는 어떠세요 ?</h2>
              <div className="recommend-wrapper">
                <Swiper
                  modules={[Navigation, Pagination]}
                  spaceBetween={10}
                  slidesPerView={2.15}
                  // loop={true}
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  {challenges?.length ? (
                    challenges.map((item, key) => (
                      <SwiperSlide
                        onClick={() => nav(`/challenge/${item.ID}`)}
                        className="recommend-card"
                      >
                        <div
                          className="content-img"
                          style={{
                            position: "relative",
                            backgroundImage: `url(${item?.thumbnail})`,
                          }}
                        >
                          <div className="noti">주 {item?.quota}회</div>
                        </div>
                        <div className="content-text-box">
                          <p className="categories">{item?.cate}</p>
                          <p className="content-title">{item?.postTitle}</p>
                          <div className="point-box">
                            <img src="/images/esg-point.svg" alt="" />
                            {item?.point}
                          </div>
                        </div>
                      </SwiperSlide>
                    ))
                  ) : (
                    <div className="empty empty2">
                      <img src="/images/icon-x-square.svg" alt="" />
                      <p className="empty-text">내역이 없습니다.</p>
                    </div>
                  )}
                </Swiper>
              </div>
            </div> */}
            <div className="gauge-wrapper">
              <h1 className="title">내 ESG 게이지</h1>
              <div className="select-wrap">
                <select
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  name=""
                  id=""
                >
                  <option value="2024">2024년</option>
                  <option value="2023">2023년</option>
                  {/* <option value="">2022년</option>
              <option value="">2021년</option> */}
                </select>
              </div>
              {/* <div className="btn-wrap">
                <div className="btn-list">
                  <div
                    className={
                      activeButton === "accomplishment"
                        ? "accomplishment active"
                        : "accomplishment"
                    }
                    onClick={() => handleButtonClick("accomplishment")}
                  >
                    달성률
                  </div>
                  <div
                    className={
                      activeButton === "participation"
                        ? "participation active"
                        : "participation"
                    }
                    onClick={() => handleButtonClick("participation")}
                  >
                    참여 챌린
                  </div>
                </div>
              </div> */}

              <>
                {/* {0 <= rate <= 25 ? (
                <div className="earth-wrap">
                  <div className="noti">0%</div>
                  <div className="img-box">
                    <img src="/images/earth.svg" alt="" className="earth" />
                  </div>
                  <p>탄소 배출 감소를 위해 힘써줘서 고마워!</p>
                </div>
              ) : null} */}
                {0 <= rate && rate <= 25 ? (
                  <div className="earth-wrap">
                    <div className="noti">
                      {user?.successNum ? user?.successNum : 0} / 52{" "}
                    </div>
                    <div className="img-box img-box2">
                      <img src="/images/earth.svg" alt="" className="earth" />
                    </div>
                    <p className="f-text">
                      탄소 배출 감소를 위해 힘써줘서 고마워!
                    </p>
                  </div>
                ) : null}
                {26 <= rate && rate <= 50 ? (
                  <div className="earth-wrap">
                    <div className="noti">
                      {user?.successNum ? user?.successNum : 0} / 52{" "}
                    </div>
                    <div className="img-box">
                      <img src="/images/earth02.svg" alt="" className="earth" />
                    </div>
                    <p>탄소 배출 감소를 위해 힘써줘서 고마워!</p>
                  </div>
                ) : null}
                {51 <= rate && rate <= 75 ? (
                  <div className="earth-wrap">
                    <div className="noti">
                      {user?.successNum ? user?.successNum : 0} / 52{" "}
                    </div>
                    <div className="img-box">
                      <img src="/images/earth03.svg" alt="" className="earth" />
                    </div>
                    <p className="l-text">
                      탄소 배출 감소를 위해 힘써줘서 고마워!
                    </p>
                  </div>
                ) : null}
                {76 <= rate && rate < 100 ? (
                  <div className="earth-wrap">
                    <div className="noti">
                      {user?.successNum ? user?.successNum : 0} / 52{" "}
                    </div>
                    <div className="img-box">
                      <img src="/images/earth04.svg" alt="" className="earth" />
                    </div>
                    <p className="e-text">
                      탄소 배출 감소를 위해 힘써줘서 고마워!
                    </p>
                  </div>
                ) : null}
                {100 <= rate ? (
                  <div className="earth-wrap">
                    <div className="noti">
                      {user?.successNum ? user?.successNum : 0} / 52{" "}
                    </div>
                    <div className="img-box">
                      <img src="/images/earth05.svg" alt="" className="earth" />
                    </div>
                    <p className="e-text">
                      탄소 배출 감소를 위해 힘써줘서 고마워!
                    </p>
                  </div>
                ) : null}
              </>

              <div className="participation-content">
                <p className="title">참여 챌린지</p>
                <p className="total">총 {total}개</p>
                {challenges?.length ? (
                  <div className="challenge-content-wrap">
                    {challenges?.length
                      ? challenges.map((item, key) => (
                          <a
                            href={`/mypage/esg/${item?.ID}`}
                            className="challenge-content-box"
                            key={key}
                          >
                            <div className="challenge-content">
                              <div
                                className="content-img"
                                style={{
                                  backgroundImage: `url(${item?.thumbnail})`,
                                }}
                              >
                                <div className="noti">주 {item?.quota}회</div>
                              </div>
                              <div className="content-text-box">
                                <p className="categories">{item?.cate}</p>
                                <p className="content-title">
                                  {item?.postTitle}
                                </p>
                                <div className="point-box">
                                  <img src="/images/esg-point.svg" alt="" />
                                  {item?.point}
                                </div>
                                <p className="number">{item?.date}</p>
                              </div>
                            </div>
                          </a>
                        ))
                      : null}
                  </div>
                ) : (
                  <div className="empty">
                    <img src="/images/icon-x-square.svg" alt="" />
                    <p className="empty-text">내역이 없습니다.</p>
                  </div>
                )}
                <div className="show-box">
                  {/* <div className="show-more">
                  더보기
                  <img src="/images/esg-plus-icon.svg" alt="" />
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </EsgmypageWrapper>
      </Layout>
    </>
  );
};

export default Mypage;
