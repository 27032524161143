import React, { useEffect, useState } from "react";
import Layout from "../../componet/Layout";
import { ChallengeWrapper } from "./styled";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import {
  ApplyChallenge,
  GetAuthByChallenge,
  GetChallenge,
  unSubscribeChallenge,
} from "../../service/challeng";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../componet/Footer";
import Alert from "../../componet/Alert";
import { useSetRecoilState } from "recoil";
import { loadingAtom } from "../../store/loading";

const AuthSingle = () => {
  //
  const params = useParams();
  console.log(params);
  const [item, setItem] = useState();
  const nav = useNavigate();
  const setLoading = useSetRecoilState(loadingAtom);

  const [reachEnd, setReachEnd] = useState(false);

  const visible = () => {
    console.log(123666);
    if (item?.certificationGallery?.length >= 2) {
      setReachEnd(true);
    }
  };

  useEffect(() => {
    setLoading(true);
    GetChallenge({
      challengeId: params?.ID,
    })
      .then((res) => {
        console.log("resssss", res);
        if (res.data.code === "200") {
          setItem(res.data.body);
          console.log(res.data.body);

          if (window.location.hash) {
            setTimeout(() => {
              window.location.href =
                window.location.pathname + window.location.hash;
            }, [100]);
          }
        } else {
          setItem(null);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [params?.ID]);
  const DoApply = () => {
    if (item?.isJoin) {
      // alert("이미 참여중입니다.");
      toggle3();
    }

    toggle();
  };

  const DoCancel = () => {
    toggle5();
  };

  const Cancel = () => {
    setLoading(true);
    unSubscribeChallenge({
      challengeId: params.ID,
    })
      .then((res) => {
        console.log(res);
        if (res.data.code === "200") {
          // alert("참여가 완료되었습니다.");
          toggle6();
        } else {
          alert(res.data.body.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const apply = () => {
    setLoading(true);
    ApplyChallenge({
      challengeId: params.ID,
    })
      .then((res) => {
        console.log(res);
        if (res.data.code === "200") {
          // alert("참여가 완료되었습니다.");
          toggle2();
        } else {
          alert(res.data.body.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const reload = () => {
    window.location.reload();
  };

  const [authes, setAuthes] = useState([]);
  const [paged, setPaged] = useState(1);
  const [maxPaged, setMaxPaged] = useState(1);
  const [total, setTotal] = useState(0);

  const DoMore = () => {
    setPaged((p) => p + 1);
  };

  useEffect(() => {
    GetAuthByChallenge({
      challengeId: params?.ID,
      isSimple: 1,
      postsPerPage: 9,
    })
      .then((res) => {
        if (res.data.code === "200") {
          setAuthes((p) => [...p, ...res.data.body.items]);
          setTotal(res.data.body.total);
          console.log(res.data.body);
        } else {
          setAuthes(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params?.ID]);

  const dummy = [
    {
      photo: "/images/esg01.png",
    },
    {
      photo: "/images/esg01.png",
    },
    {
      photo: "/images/esg01.png",
    },
    {
      photo: "/images/esg01.png",
    },
  ];

  const [active, setActive] = useState(false);
  const toggle = () => {
    setActive((p) => !p);
  };

  const [active2, setActive2] = useState(false);
  const toggle2 = () => {
    setActive2((p) => !p);
  };

  const [active3, setActive3] = useState(false);
  const toggle3 = () => {
    setActive3((p) => !p);
  };

  const [active4, setActive4] = useState(false);
  const toggle4 = () => {
    setActive4((p) => !p);
  };

  const [active5, setActive5] = useState(false);
  const toggle5 = () => {
    setActive5((p) => !p);
  };

  const [active6, setActive6] = useState(false);
  const toggle6 = () => {
    setActive6((p) => !p);
  };

  return (
    <div>
      <Layout footerType={"none"} headerTitle={item?.postTitle}>
        <ChallengeWrapper>
          <div className="wrapper">
            <div className="main-wrapper">
              <div
                className="main-banner"
                style={{
                  backgroundImage: `url(${item?.thumbnail})`,
                }}
              >
                <div className="noti-box">
                  {/* <div className="noti">🥇 챌린지 랭킹 1위</div> */}
                  {item?.rate ? (
                    <div className="noti">🏃‍♂️ 챌린지 달성률 {item?.rate}%</div>
                  ) : null}
                </div>
                <div className="under-menu">
                  <div className="Participation">
                    <div className="user-list-box">
                      {item?.joiner?.length
                        ? item?.joiner.map((i, k) => (
                            <div
                              style={{ backgroundImage: `url(${i?.avatar})` }}
                              className="user-list"
                            ></div>
                          ))
                        : null}
                    </div>
                    <div className="Participation-title">
                      <h2>
                        +{item?.participantNum ? item?.participantNum : 0}
                        <span>명 참여중</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="health-box">
              <div className="top-menu">
                <span>{item?.cate}</span>
                <p>주 {item?.quota}회</p>
              </div>
              <h2 className="health-title">{item?.postTitle}</h2>
              <div className="point-box">
                <img src="/images/esg-point.svg" alt="" />
                {item?.point}
              </div>
            </div>
            <div className="bold-line"></div>
            {typeof item?.notes == "object" && item?.target?.length ? (
              <div className="recommend-wrap">
                <h2 className="title re-title">이런 분들께 추천드려요!</h2>
                {typeof item?.notes == "object" && item?.target?.length
                  ? item?.target?.map((item, index) => (
                      <div className="list-box">
                        <div className="recommend-list">{item.title}</div>
                      </div>
                    ))
                  : null}
              </div>
            ) : null}
            {item?.certificationNotes ? (
              <div className="Certified-wrap">
                <h2 id="notice" className="title c-title">
                  인증할 때 지켜주세요!
                </h2>
                <div className="post-wrapper">
                  <Swiper
                    modules={[Navigation, Pagination]}
                    spaceBetween={10}
                    slidesPerView={1.8}
                    // loop={true}
                    onSlideChangeTransitionStart={() => setReachEnd(false)}
                    onSwiper={(swiper) => console.log(swiper)}
                    // onReachEnd={() =>
                    //   item?.certificationGallery?.length > 2
                    //     ? () => alert(1)
                    //     : () => setReachEnd(false)
                    // }
                    onReachEnd={() => visible()}
                  >
                    {item?.certificationGallery?.length &&
                    item?.certificationGallery[0]?.image
                      ? item?.certificationGallery?.map((gallery, index) => (
                          <SwiperSlide
                            className="certified-card"
                            key={index}
                            style={{
                              backgroundImage: `url(${gallery.image})`,
                            }}
                          >
                            <div className="ex">인증예시</div>
                          </SwiperSlide>
                        ))
                      : null}
                  </Swiper>
                  <div className={reachEnd ? "bg none" : "bg"}></div>
                </div>
                <div className="Certified-noti">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: item?.certificationNotes,
                    }}
                  ></p>
                  {/* <p>{item?.certificationNotes}</p> */}
                  {/* <p>
                  인증 시, 당일 날짜와 내 걸음수가 한 화면에 나오도록
                  캡쳐해야합니다. 날짜가 인증 당일이 아니거나, 걸음수가 7000보
                  이상이 되지 않은 인증샷은 인정되지 않습니다. 인증 당일 안으로
                  규칙에 맞춰 재인증 해주시기 바랍니다. <br />
                  <br />* 사진첩 연동이 되는 챌린지입니다
                </p> */}
                </div>
              </div>
            ) : null}
            {item?.notes?.length &&
            item?.notes[0]?.content &&
            typeof item?.notes == "object" ? (
              <div className="noti-wrap">
                <h2 className="title">주의사항</h2>
                <div className="noti-box">
                  {item?.notes?.length && typeof item?.notes == "object"
                    ? item?.notes.map((item, key) => <p>{item?.content}</p>)
                    : null}
                </div>
              </div>
            ) : null}
            <div>
              <div className="all-wrapper">
                {/* <div className="bold-line"></div> */}
                <div className="situation-wrap">
                  <p className="title">모든 참여자들의 인증</p>
                  <>
                    <div className="myshot-box">
                      {authes?.length ? (
                        authes.map((i, key) => (
                          <div
                            className="myshot"
                            key={key}
                            style={{
                              backgroundImage: `url(${i?.thumbnail})`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                            onClick={() =>
                              nav(`/authlist/${params?.ID}#${i.ID}`)
                            }
                          ></div>
                        ))
                      ) : authes ? (
                        ""
                      ) : (
                        <div className="empty">
                          <img src="/images/icon-x-square.svg" alt="" />
                          <p className="empty-text">
                            아직 등록된 인증이 없습니다.
                          </p>
                        </div>
                      )}
                    </div>
                    {total <= 9 ? null : (
                      <div className="more-btn-box">
                        <Link to={`/other/${params?.ID}`}>
                          더보기
                          <img src="/images/more-plus.svg" alt="" />
                        </Link>
                      </div>
                    )}
                  </>
                </div>
                {item?.isTodayAuth && item?.authComplete ? (
                  <div className="certified-btn-gray">
                    <a>인증 완료</a>
                  </div>
                ) : (
                  <div className="certified-btn">
                    <a href={`/auth/${item?.ID}`}>인증하기</a>
                    {/* <div className="bar"></div> */}
                  </div>
                )}
              </div>
            </div>

            <div className="btn-box">
              {/* {item?.isPossible ? (
              ) : (
              )} */}
              {item?.isSubscribed ? (
                <a onClick={DoCancel} className="participate-btn-black">
                  중단하기
                </a>
              ) : item?.isJoin ? (
                <a className="participate-btn">참여중</a>
              ) : (
                <a onClick={DoApply} className="participate-btn">
                  참여하기
                </a>
              )}
              {/* <div className="bar"></div> */}
            </div>
          </div>
          <Alert
            active={active}
            toggle={toggle}
            event={apply}
            title="참여하시겠습니까?"
          />
          <Alert
            active={active2}
            toggle={toggle2}
            title="참여가 완료되었습니다."
            oneEvent={true}
            event={reload}
          />
          <Alert
            active={active3}
            toggle={toggle3}
            title="이미 참여중입니다."
            oneEvent={true}
          />
          <Alert
            active={active4}
            toggle={toggle4}
            title="이미 참여중입니다."
            oneEvent={true}
          />
          <Alert
            active={active5}
            toggle={toggle5}
            title="챌린지를 중단하시겠습니까?"
            event={Cancel}
          />
          <Alert
            active={active6}
            toggle={toggle6}
            title="참여중인 챌린지를 중단합니다."
            oneEvent={true}
            event={reload}
          />
        </ChallengeWrapper>
        <Footer />
      </Layout>
    </div>
  );
};

export default AuthSingle;
