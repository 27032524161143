import React, { useEffect, useState } from "react";
import Layout from "../../componet/Layout";
import { GaugeWrapper } from "./styled";
import { GetMeEsg } from "../../service/me";
import { GetJoinsAll } from "../../service/challeng";

const Gauge = () => {
  const [activeButton, setActiveButton] = useState("accomplishment");

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };
  const [year, setYear] = useState("2024");
  const [rate, setRate] = useState(-1);
  const [challenges, setChallenges] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    GetMeEsg({
      year: year,
    })
      .then((res) => {
        if (res.data.code == "200") {
          console.log(res);
          setRate(res.data.body.rate);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [year]);

  useEffect(() => {
    GetJoinsAll({
      year,
    })
      .then((res) => {
        if (res.data.code == "200") {
          setChallenges(res.data.body.items);
          setTotal(res.data.body.total);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [year]);

  const [hasContent] = useState(true);

  return (
    <Layout headerTitle={"내 ESG 게이지"} footerType={"none"}>
      <GaugeWrapper>
        <div className="wrapper">
          <div className="select-wrap">
            <select
              value={year}
              onChange={(e) => setYear(e.target.value)}
              name=""
              id=""
            >
              <option value="2024">2024년</option>
              <option value="2023">2023년</option>
              {/* <option value="">2022년</option>
              <option value="">2021년</option> */}
            </select>
          </div>
          <div className="btn-wrap">
            <div className="btn-list">
              <div
                className={
                  activeButton === "accomplishment"
                    ? "accomplishment active"
                    : "accomplishment"
                }
                onClick={() => handleButtonClick("accomplishment")}
              >
                달성률
              </div>
              <div
                className={
                  activeButton === "participation"
                    ? "participation active"
                    : "participation"
                }
                onClick={() => handleButtonClick("participation")}
              >
                참여 챌린지
              </div>
            </div>
          </div>
          {activeButton === "accomplishment" ? (
            <>
              {/* {0 <= rate <= 25 ? (
                <div className="earth-wrap">
                  <div className="noti">0%</div>
                  <div className="img-box">
                    <img src="/images/earth.svg" alt="" className="earth" />
                  </div>
                  <p>탄소 배출 감소를 위해 힘써줘서 고마워!</p>
                </div>
              ) : null} */}
              {0 <= rate && rate <= 25 ? (
                <div className="earth-wrap">
                  <div className="noti">{rate}%</div>
                  <div className="img-box img-box2">
                    <img src="/images/earth.svg" alt="" className="earth" />
                  </div>
                  <p className="f-text">
                    탄소 배출 감소를 위해 힘써줘서 고마워!
                  </p>
                </div>
              ) : null}
              {26 <= rate && rate <= 50 ? (
                <div className="earth-wrap">
                  <div className="noti">{rate}%</div>
                  <div className="img-box">
                    <img src="/images/earth02.svg" alt="" className="earth" />
                  </div>
                  <p>탄소 배출 감소를 위해 힘써줘서 고마워!</p>
                </div>
              ) : null}
              {51 <= rate && rate <= 75 ? (
                <div className="earth-wrap">
                  <div className="noti">{rate}%</div>
                  <div className="img-box">
                    <img src="/images/earth03.svg" alt="" className="earth" />
                  </div>
                  <p className="l-text">
                    탄소 배출 감소를 위해 힘써줘서 고마워!
                  </p>
                </div>
              ) : null}
              {76 <= rate ? (
                <div className="earth-wrap">
                  <div className="noti">{rate}%</div>
                  <div className="img-box">
                    <img src="/images/earth04.svg" alt="" className="earth" />
                  </div>
                  <p className="e-text">
                    탄소 배출 감소를 위해 힘써줘서 고마워!
                  </p>
                </div>
              ) : null}
              {100 <= rate ? (
                <div className="earth-wrap">
                  <div className="noti">{rate}%</div>
                  <div className="img-box">
                    <img src="/images/earth05.svg" alt="" className="earth" />
                  </div>
                  <p className="e-text">
                    탄소 배출 감소를 위해 힘써줘서 고마워!
                  </p>
                </div>
              ) : null}
            </>
          ) : (
            <div className="participation-content">
              <p className="total">총 {total}개</p>
              {hasContent ? (
                <div className="challenge-content-wrap">
                  {challenges?.length
                    ? challenges.map((item, key) => (
                        <a
                          href={`/challenge/${item?.ID}`}
                          className="challenge-content-box"
                          key={key}
                        >
                          <div className="challenge-content">
                            <div
                              className="content-img"
                              style={{
                                backgroundImage: `url(${item?.thumbnail})`,
                              }}
                            >
                              <div className="noti">주 {item?.quota}회</div>
                            </div>
                            <div className="content-text-box">
                              <p className="categories">{item?.cate}</p>
                              <p className="content-title">{item?.postTitle}</p>
                              <div className="point-box">
                                <img src="/images/esg-point.svg" alt="" />
                                {item?.point}
                              </div>
                              <p className="number">{item?.joinNum}회 참여</p>
                            </div>
                          </div>
                        </a>
                      ))
                    : null}
                </div>
              ) : (
                <div className="empty">
                  <img src="/images/icon-x-square.svg" alt="" />
                  <p className="empty-text">내역이 없습니다.</p>
                </div>
              )}
              <div className="show-box">
                {/* <div className="show-more">
                  더보기
                  <img src="/images/esg-plus-icon.svg" alt="" />
                </div> */}
              </div>
            </div>
          )}
        </div>
      </GaugeWrapper>
    </Layout>
  );
};

export default Gauge;
