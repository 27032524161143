import styled from "styled-components";

export const ChallengeWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  .wrapper {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
  }
  a {
    text-decoration: none;
    color: #000;
  }
  .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 160%;
    letter-spacing: -0.36px;
  }

  .post-wrapper {
    margin-top: 16px;
    max-width: calc(100% - 40px);
    /* transform: translateX(-20px); */
    margin: 0 auto;
    overflow: visible;
    .swiper {
      overflow: visible !important;
    }
  }
  .c-title {
    padding: 0 20px;
  }
  .main-wrapper {
    padding-top: 78px;

    .main-banner {
      /* background-image: url(/images/health.png); */
      width: 100%;
      height: 0;
      padding-bottom: 76%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      &:after {
        position: absolute;
        content: "";
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.8) 100%
        );
        width: 100%;
        height: 136px;
        bottom: 0;
      }
      .noti-box {
        display: flex;
        gap: 10px;
        padding: 16px 20px;
        .noti {
          border-radius: 6px;
          background: rgba(0, 0, 0, 0.56);
          color: #fff;
          font-size: 13px;
          font-weight: 500;
          letter-spacing: -0.26px;
          height: 34px;
          padding: 0 12px 0 9px;
          line-height: 34px;
        }
      }
      .under-menu {
        color: #fff;
        padding: 20px;
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 1;
        .Participation {
          margin-top: 11px;
          display: flex;
          justify-content: end;
          align-items: center;
          gap: 8px;
          .user-list-box {
            display: flex;
            .user-list {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background-image: url(/images/esg-user.png);
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
              margin-left: -8px;
            }
          }
          .Participation-title {
            h2 {
              color: #ffbc00;
              font-size: 14px;
              font-weight: 500;
              line-height: normal;
              letter-spacing: -0.28px;
            }
            span {
              color: #fff;
            }
          }
        }
      }
    }
  }
  .health-box {
    padding: 20px;
    .top-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 2px;
      span {
        color: #666;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.28px;
      }
      p {
        border-radius: 4px;
        background: rgba(0, 0, 0, 0.8);
        color: #fff;
        font-size: 13px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.26px;
        padding: 4px 8px;
      }
    }
    .health-title {
      font-size: 20px;
      font-weight: 700;
      line-height: 160%;
      letter-spacing: -0.4px;
    }
    .point-box {
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.32px;
      margin-top: 10px;
      img {
        width: 20px;
      }
    }
  }
  .bold-line {
    border-top: 8px solid #00000008;
    margin: 12px 0 40px 0;
  }
  .recommend-wrap {
    padding: 0 20px;
    .re-title {
      margin-bottom: 16px;
    }
    .list-box {
      margin-top: 10px;
      .recommend-list {
        border-radius: 10px;
        background: rgba(255, 188, 0, 0.12);
        margin-top: 10px;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 46px;
        letter-spacing: -0.28px;
        height: 48px;
      }
    }
  }
  .Certified-wrap {
    margin-top: 40px;
    overflow: hidden;
    /* padding-left: 20px; */
    /* padding-bottom: 130px; */

    .Certified-noti {
      margin-top: 10px;
      border-radius: 10px;
      background: #f7f7f7;
      padding: 18px 16px;
      margin-right: 20px;
      margin-left: 20px;
      p {
        font-size: 13px;
        font-weight: 400;
        line-height: 160%;
        letter-spacing: -0.26px;
        word-break: keep-all;
      }
    }
  }
  .noti-wrap {
    margin-top: 20px;
    padding: 20px;
    .noti-box {
      margin-top: 16px;
      border-radius: 10px;
      background: #f7f7f7;
      padding: 18px 16px;
      margin-bottom: 50px;
      p {
        font-size: 13px;
        font-weight: 400;
        line-height: 160%;
        position: relative;
        padding-left: 20px;
        word-break: keep-all;
        &:after {
          position: absolute;
          content: "";
          top: 8px;
          left: 10px;
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background-color: #000;
        }
      }
    }
  }
  .post-wrapper {
    position: relative;
    margin-top: 16px;
  }
  .certified-card {
    /* background-image: url(/images/challenge-swiper.png); */
    background-repeat: no-repeat;
    background-size: cover;
    width: 180px;
    height: 180px;
    border-radius: 10px;
    position: relative;
    .ex {
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.56);
      color: #fff;
      font-size: 11px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.22px;
      display: inline-block;
      padding: 4px 6px;
      position: absolute;
      top: 8px;
      left: 8px;
    }
  }
  .bg {
    width: 50px;
    height: 180px;
    background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    opacity: 1;
    visibility: visible;
    transform: translateX(20px);
  }
  .bg.none {
    opacity: 0;
    visibility: hidden;
  }
  .btn-box {
    position: fixed;
    z-index: 100;
    max-width: 500px;
    width: 100%;
    bottom: 51px;
    padding: 0 20px 10px 20px;
    border-top: 1px solid #e9e9e9;
    background-color: #fff;
    .bar {
      width: 130px;
      height: 5px;
      border-radius: 30px;
      background: #000;
      position: absolute;
      bottom: 8px;
      left: 50%;
      transform: translateX(-50%);
    }
    .participate-btn {
      border-radius: 10px;
      background: #ffbc00;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 50px;
      width: 100%;
      height: 52px;
      margin-top: 10px;
      cursor: pointer;
      transition: all 0.3s;
      font-family: Noto Sans KR;
      display: block;
      &:hover {
        opacity: 0.8;
      }
    }
    .participate-btn-black {
      border-radius: 10px;
      background: #000;
      text-align: center;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 50px;
      width: 100%;
      height: 52px;
      margin-top: 10px;
      cursor: pointer;
      transition: all 0.3s;
      font-family: Noto Sans KR;
      display: block;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .all-wrapper {
    padding-bottom: 120px;
    margin-top: -30px;
    .situation-wrap {
      padding: 0 20px;
    }

    .myshot-box {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
    .myshot {
      border-radius: 10px;
      border: 1px solid #e9e9e9;
      width: calc((100% - 20px) / 3);
      height: 0;
      padding-bottom: 31.4%;
      transition: all 0.3s;
      cursor: pointer;
      display: block;
      &:hover {
        opacity: 0.8;
      }
      .myshot-img {
        width: 100%;
        height: 0;
        padding-bottom: 69%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 10px;
      }
    }
    .certified-btn {
      padding: 10px 20px;
      border-top: 1px solid #e9e9e9;
      background-color: #fff;
      position: fixed;
      bottom: 0;
      max-width: 500px;
      width: 100%;
      .bar {
        width: 130px;
        height: 5px;
        border-radius: 30px;
        background: #000;
        position: absolute;
        bottom: 8px;
        left: 50%;
        transform: translateX(-50%);
      }
      a {
        border-radius: 10px;
        background: #ffbc00;
        display: block;
        width: 100%;
        height: 50px;
        color: #000;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 48px;
        transition: all 0.3s;
        &:hover {
          opacity: 0.8;
        }
      }
    }

    .certified-btn-gray {
      padding: 10px 20px;
      border-top: 1px solid #e9e9e9;
      position: relative;
      .bar {
        width: 130px;
        height: 5px;
        border-radius: 30px;
        background: #000;
        position: absolute;
        bottom: 8px;
        left: 50%;
        transform: translateX(-50%);
      }
      a {
        border-radius: 10px;
        border: 1px solid #e9e9e9;
        background: #f7f7f7;
        display: block;
        width: 100%;
        height: 50px;
        color: #000;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 48px;
      }
    }
    .more-btn-box {
      margin-top: 30px;
      margin-bottom: 66px;
      a {
        border-radius: 50px;
        border: 1px solid #e9e9e9;
        width: 112px;
        height: 42px;
        display: block;
        margin: 0 auto;
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        line-height: 42px;
        letter-spacing: -0.26px;
        transition: all 0.3s;
        &:hover {
          opacity: 0.6;
        }
        img {
          margin-left: 6px;
          position: relative;
          top: -2px;
        }
      }
    }
  }
  @media ${(props) => props.theme.mobile} {
  }
`;
