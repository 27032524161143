import React, { useEffect, useState } from "react";
import Layout from "../../componet/Layout";
import { CompensationWrapper } from "./styled";
import { Getrewards, Postrewards } from "../../service/rewards";
import { useRecoilState } from "recoil";
import { userData } from "../../store/users";
import Alert from "../../componet/Alert";

const Rewards = () => {
  //
  const [user, setUser] = useRecoilState(userData);

  const [paged, setPaged] = useState(1);
  const postsPerPage = 8;
  const [rewards, setRewards] = useState([]);
  const [total, setTotal] = useState(0);

  const Submit = () => {
    Postrewards()
      .then((res) => {
        console.log(res);
        if (res.data.code === "200") {
          toggle2();
        } else {
          toggle3();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const reload = () => {
    window.location.reload();
  };

  const handleConfirmation = () => {
    toggle();
  };

  useEffect(() => {
    Getrewards({
      postsPerPage: postsPerPage,
      paged: paged,
    })
      .then((res) => {
        console.log(res);
        if (res.data.code === "200") {
          setRewards(res.data.body.items);
          setTotal(res.data.body.total);
        } else {
          setRewards(null);
          setTotal(0);
        }
      })
      .catch((err) => {
        console.log(123);
      });
  }, []);

  const [active, setActive] = useState(false);
  const toggle = () => {
    setActive((p) => !p);
  };

  const [active2, setActive2] = useState(false);
  const toggle2 = () => {
    setActive2((p) => !p);
  };

  const [active3, setActive3] = useState(false);
  const toggle3 = () => {
    setActive3((p) => !p);
  };

  const [active4, setActive4] = useState(false);
  const toggle4 = () => {
    setActive4((p) => !p);
  };

  return (
    <div>
      <Layout
        headerType={"sub"}
        headerTitle={"에코 포인트"}
        footerType={"reward"}
      >
        <CompensationWrapper>
          <div className="wrapper">
            <div className="point-wrap">
              <div className="point-box">
                <img src="/images/esg-point.svg" alt="" />
                <p className="total">{user?.point}</p>
                <p className="accrue">누적 {user?.totalPoint}</p>
              </div>
              {user?.numberPoint >= 3800 ? (
                <div
                  className="compensation-btn-box"
                  onClick={handleConfirmation}
                >
                  <a href="#">
                    <img src="/images/icon-bookmark.svg" alt="" />
                    포인트 신청하기
                  </a>
                </div>
              ) : (
                <div className="deactivation-btn-box">
                  <a href="#">
                    <img src="/images/icon-bookmark-gray.svg" alt="" />
                    포인트 신청하기
                  </a>
                </div>
              )}

              <div className="compensation-noti">
                <p>
                  포인트신청은 3,800 eco 단위로 가능합니다.
                  <br />
                  포인트신청 완료 시 신청 취소는 불가하며, 1일 최대 2회 신청
                  가능합니다.
                  <br /> (에코 포인트는 매년 1월 1일 초기화됩니다.)
                </p>
              </div>
            </div>
            <div className="bold-line"></div>
            <div className="breakdown">
              <h2>
                포인트 신청 내역
                <select>
                  <option value="2023">2023년</option>
                </select>
              </h2>
              {rewards?.length ? (
                <div>
                  {rewards.map((item, index) => (
                    <div className="breakdown-box" key={index}>
                      <div className="top-box">
                        <p className="date">{item.date}</p>
                        <p className="use">-{item.amount}</p>
                      </div>
                      <div className="under-box">
                        <p className="completed">
                          {item.status == "publish" ? "보상 완료" : ""}
                          {item.status == "pending" ? "신청 완료" : ""}
                        </p>
                        <p className="total-point">잔여 {item.balance}</p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : rewards ? (
                ""
              ) : (
                <div className="empty">
                  <img src="/images/icon-x-square.svg" alt="" />
                  <p className="empty-text">내역이 없습니다.</p>
                </div>
              )}
            </div>
          </div>
          <Alert
            active={active}
            toggle={toggle}
            event={Submit}
            title="신청하시겠습니까?"
          />
          <Alert
            active={active2}
            toggle={toggle2}
            title="신청이 완료되었습니다."
            event={reload}
            oneEvent={true}
          />
          <Alert
            active={active3}
            toggle={toggle3}
            title="신청에 실패했습니다. 관리자에게 문의해주세요."
            event={reload}
            oneEvent={true}
          />
        </CompensationWrapper>
      </Layout>
    </div>
  );
};

export default Rewards;
