import initAxios from "./defaultClient";

const prefix = "/me";

export const GetMe = async (args) => {
  const axios = initAxios();
  return await axios.get(prefix + "/", {
    params: args,
  });
};

export const GetMeEsg = async (args) => {
  const axios = initAxios();
  return await axios.get(prefix + "/esg", {
    params: args,
  });
};
