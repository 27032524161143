import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 10000;
  transition: 0.2s;
  .overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    transition: 0.2s;
  }
  .container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: ${(props) => (props.width ? props.width : `298px`)};
    width: 100%;
    height: ${(props) => (props.height ? props.height : `auto`)};
    z-index: 100;
    border-radius: 10px;
    background: #fff;
    transition: 0.2s;
    padding: 26px 15px 15px 15px;
    text-align: center;
    .title {
      font-size: 18px;
      font-weight: 700;
      line-height: normal;
    }
    p {
      color: #666;

      margin-top: 9px;
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
    }
    .btn-wrap {
      margin-top: 18px;
      display: flex;
      gap: 8px;
      justify-content: space-between;
      .btn {
        border-radius: 10px;
        background: #ffbc00;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 46px;
        letter-spacing: -0.28px;
        width: 50%;
        height: 46px;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          opacity: 0.8;
        }
        &.p {
          width: 100%;
        }
      }
      .close {
        border-radius: 10px;
        border: 1px solid #e9e9e9;
        background: #f7f7f7;
      }
    }
  }

  /* &.active {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
    .overlay {
      opacity: 1;
      visibility: visible;
      z-index: 50;
    }
  } */

  ${(props) =>
    props.active
      ? `
      opacity: 1;
      visibility: visible;
      z-index: 9999999999;
      .overlay {
        opacity: 1;
        visibility: visible;
        z-index: 50;
      }
    `
      : `transition: 0s;`}
`;
