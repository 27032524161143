import styled from "styled-components";

export const CertifiedWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  #tmp {
    position: relative;
    top: -72px;
    /* transform: translateY(-500px); */
  }
  .r {
    position: absolute;
    right: 16px;
    bottom: 18px;
    z-index: 10;
    width: 30px;
    height: 30px;
    padding: 6px;
    z-index: 10;
  }
  .modal {
    position: fixed;
    display: flex;
    align-items: center;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    max-width: 500px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    img {
      width: 100%;
      height: auto;
    }
    .closebtn {
      position: absolute;
      top: 128px;
      right: 20px;
      width: 20px;
      height: 20px;
      z-index: 10;
      cursor: pointer;
    }
    div {
      width: 100%;
      height: 100%;
      max-height: 70vh;

      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
  .wrapper {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
    padding-bottom: 100px;
  }
  a {
    text-decoration: none;
    color: #000;
  }
  .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 160%;
    letter-spacing: -0.36px;
  }

  .popular-box {
    padding-top: 161px;
    position: relative;
    .tab-menu {
      margin-top: 56px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      gap: 40px;
      border-bottom: 1px solid #eee;
      margin-bottom: 20px;
      li {
        color: #9b9b9b;
        font-family: Noto Sans KR;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.7px;
        border-bottom: 2px solid transparent;
        padding-bottom: 10px;
        cursor: pointer;
        display: flex;
        gap: 3px;
        align-items: center;
        &.active {
          color: #000;
          border-bottom: 2px solid #000;
        }
        &:hover {
          opacity: 0.8;
        }
        &.active span {
          border-radius: 30px;
          background: #000;
          width: 24px;
          height: 20px;
          display: inline-block;
          color: #fff;
          font-family: Noto Sans KR;
          font-size: 11px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.55px;
          text-align: center;
        }
      }
    }
    .certified-wrap {
      &:last-child {
        padding-bottom: 30px;
      }
      .my-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 20px 17px 20px;
        margin-top: -5px;
        .left-info {
          display: flex;
          align-items: center;
          gap: 7px;
          .user-img {
            border: 1px solid #eee;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            /* background-image: url(/images/esg-user.png); */
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }
          p {
            font-size: 13px;
            font-weight: 700;
            letter-spacing: -0.26px;
            line-height: normal;
          }
          span {
            font-size: 11px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.22px;
            color: #999;
            font-family: Noto Sans KR;
          }
        }
        .icon-recycle {
          cursor: pointer;
          transition: all 0.3s;
          &:hover {
            opacity: 0.6;
          }
        }
      }
      .photo {
        width: 100%;
        height: 360px;
        position: relative;
        &:after {
          position: absolute;
          content: "";
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.8) 100%
          );
          width: 100%;
          height: 136px;
          bottom: 0;
        }
        .like-box {
          display: flex;
          align-items: center;
          gap: 20px;
          position: absolute;
          bottom: 20px;
          left: 20px;
          z-index: 1;
          button {
            border: none;
            background-color: transparent;
            color: #fff;
            font-family: Noto Sans KR;
            font-size: 14px;
            font-weight: 500;
            line-height: 160%;
            img {
              margin-right: 7px;
              margin-top: -3px;
            }
          }
          p {
            color: #fff;
            font-family: Noto Sans KR;
            font-size: 14px;
            font-weight: 500;
            line-height: 160%;
            cursor: pointer;
            img {
              margin-right: 7px;
              margin-top: -3px;
            }
            .like-btn {
              cursor: pointer;
              width: 17px;
            }
          }
        }
      }
      .photo-content {
        padding: 0 20px;
        margin-top: 16px;
        .photo-title {
          font-size: 14px;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.28px;
        }
        .photo-text {
          margin-top: 6px;
          font-size: 13px;
          font-weight: 400;
          line-height: 150%;
          margin-bottom: 30px;
        }
      }
    }
  }
  .comment-box {
    border-top: 1px solid #e9e9e9;
    max-width: 500px;
    width: 100%;
    background-color: #fff;
    padding: 0 20px;

    .user-info {
      display: flex;
      gap: 8px;
      align-items: center;
      margin-top: 20px;
      .user {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        cursor: pointer;
      }
      .name {
        font-size: 13px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.26px;
      }
    }
    .comment {
      margin-top: 5px;
      font-size: 13px;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: -0.26px;
      padding-left: 28px;
    }
    .date {
      color: #999;
      font-size: 11px;
      font-weight: 400;
      letter-spacing: -0.22px;
      padding-left: 28px;
      margin-top: 5px;
    }
  }
  .chat-box {
    margin-top: 20px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    .my-profile {
      flex: 0 0 36px;
      width: 46px;
      height: 36px;
      border-radius: 50%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
    input {
      border-radius: 70px;
      border: 1px solid #e9e9e9;
      background: #fff;
      position: relative;
      width: 100%;
      padding: 11px 10px 11px 18px;
      margin-left: 10px;
      font-size: 12px;
      font-weight: 400;
      line-height: 160%;
      padding-right: 50px;
      &::placeholder {
        color: #999;
      }
    }
    .send {
      position: absolute;
      right: 36px;
      cursor: pointer;
    }
  }

  .other-profile {
    display: none;
    background-color: #fff;
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.05);
    position: fixed;
    bottom: 0px;
    max-width: 500px;
    width: 100%;
    padding-bottom: 30px;
    transition: all 0.3s;
    transform: translateY(100%);
    &.active {
      transform: translateY(0);
    }
    .bar {
      width: 56px;
      height: 4px;
      background: #e9ecef;
      border-radius: 10px;
      margin: 0 auto;
      position: absolute;
      top: 12px;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }
    .my-info {
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 30px 20px;
      .user-img {
        border: 1px solid #eee;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background-image: url(/images/esg-user.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
      p {
        font-size: 16px;
        font-weight: 700;
        line-height: 160%;
        letter-spacing: -0.32px;
      }
      span {
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.28px;
        display: flex;
        gap: 8px;
        font-family: Noto Sans KR;
      }
    }
    .situation-wrap {
      display: flex;
      a {
        width: 50%;
        text-align: center;
        border-right: 1px solid #e9e9e9;
        transition: all 0.3s;
        &:last-child {
          border-right: none;
        }
        p {
          color: #666;
          font-size: 12px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.24px;
        }
        h5 {
          font-size: 16px;
          font-weight: 700;
          letter-spacing: -0.32px;
          margin-top: 8px;
        }
        &:hover {
          opacity: 0.6;
        }
      }
    }
    .profile-btn {
      margin-top: 20px;
      padding: 0 20px;

      a {
        img {
          margin-right: 6px;
        }
        width: 100%;
        height: 46px;
        border-radius: 10px;
        background: #f7f7f7;
        display: block;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 44px;
        letter-spacing: -0.28px;
      }
    }
  }
  .bar {
    width: 56px;
    height: 4px;
    background: #e9ecef;
    border-radius: 10px;
    margin: 0 auto;
  }
  .logo-box {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 14px;
    h2 {
      font-family: Noto Sans KR;
      font-size: 18px;
      font-weight: 700;
      line-height: 160%;
      letter-spacing: -0.54px;
      display: flex;
      gap: 6px;
      margin-left: -10px;
      text-align: center;
      justify-content: center;
      width: 100%;
      span {
        color: rgba(0, 0, 0, 0.6);
      }
    }
    img {
      cursor: pointer;
    }
  }
  .tab-menu {
    display: flex;
    align-items: center;
    gap: 40px;
    position: fixed;
    z-index: 123456789;
    background-color: #fff;
    max-width: 500px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 20px;
    margin-top: 98px;

    li {
      color: #9b9b9b;
      font-family: Noto Sans KR;
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.7px;
      border-bottom: 2px solid transparent;
      padding-bottom: 10px;
      cursor: pointer;
      display: flex;
      gap: 3px;
      align-items: center;

      &.active {
        color: #000;
        border-bottom: 2px solid #000;
      }
      &:hover {
        opacity: 0.8;
      }
      &.active span {
        border-radius: 30px;
        background: #000;
        width: 24px;
        height: 20px;
        display: inline-block;
        color: #fff;
        font-family: Noto Sans KR;
        font-size: 11px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.55px;
        text-align: center;
      }
    }
  }
  @media ${(props) => props.theme.mobile} {
  }
`;
