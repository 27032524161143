import styled from "styled-components";

export const WithChallengeWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);

  .wrapper {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
    padding-bottom: 30px;
  }
  .tab-menu {
    padding: 0 20px;
    display: flex;
    align-items: center;
    gap: 40px;
    max-width: 500px;
    width: 100%;
    /* position: fixed; */
    /* left: 50%; */
    /* transform: translateX(-50%); */
    z-index: 1232;
    margin-top: 20px;
    /* top: 73px; */

    li {
      color: #9b9b9b;
      font-family: Noto Sans KR;
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.7px;
      border-bottom: 2px solid transparent;
      padding-bottom: 10px;
      cursor: pointer;
      display: flex;
      gap: 3px;
      align-items: center;
      &.active {
        color: #000;
        border-bottom: 2px solid #000;
      }
      &:hover {
        opacity: 0.8;
      }
      &.active span {
        border-radius: 30px;
        background: #000;
        width: 24px;
        height: 20px;
        display: inline-block;
        color: #fff;
        font-family: Noto Sans KR;
        font-size: 11px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.55px;
        text-align: center;
      }
    }
  }
  a {
    text-decoration: none;
    color: #000;
  }
  .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 160%;
    letter-spacing: -0.36px;
  }

  .main-wrapper {
    padding-top: 50px;
    padding: 96px 20px 0 20px;
    .main-banner {
      background-color: #fff5be;
      background-image: url(/images/alone-banner.png);
      width: 100%;
      height: 76px;
      /* padding-bottom: 23.8%; */
      background-size: 142px;
      background-repeat: no-repeat;
      background-position: bottom 0 right 10px;
      border-radius: 10px;
      position: relative;
      .main-title {
        font-size: 16px;
        font-weight: 700;
        line-height: 150%;
        letter-spacing: -0.32px;
        padding: 25px;
        position: relative;
        top: 3px;
      }
    }
  }
  .popular-box {
    .popular-title {
      font-size: 20px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.4px;
    }
    .tab-menu {
      margin-top: 20px;
      display: flex;
      align-items: center;
      gap: 40px;
      border-bottom: 1px solid #eeeeee;
      padding: 0 20px;
      margin-left: 10px;
      li {
        color: #9b9b9b;
        font-family: Noto Sans KR;
        font-size: 14px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.7px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        padding-bottom: 9px;
        transition: all 0.3s;
        &:hover {
          opacity: 0.8;
        }
        &.active {
          color: #000;
          border-bottom: 2px solid #000;
        }
      }
    }
  }
  .challenge-content-wrap {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 0 20px;
    .challenge-content-box {
      width: calc((100% - 10px) / 2);
    }
    .challenge-content {
      margin-bottom: 10px;
      position: relative;

      &:hover .dim {
        opacity: 1;
      }
      .dim {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.7);
        opacity: 0;
        transition: all 0.3s;
        p {
          color: #fff;
          font-size: 12px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.24px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .content-img {
        width: 100%;
        height: 0;
        padding-bottom: 65.3%;
        background-image: url(/images/popular-challenge01.png);
        border-radius: 10px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: relative;
        .noti {
          border-radius: 4px;
          background: rgba(0, 0, 0, 0.56);
          display: inline-block;
          color: #fff;
          font-size: 11px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.22px;
          padding: 4px 6px 5px 6px;
          position: absolute;
          top: 8px;
          right: 14px;
        }
      }
      .content-text-box {
        margin-top: 10px;
        .categories {
          color: #666;
          font-size: 12px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.24px;
        }
        .content-title {
          font-size: 14px;
          font-weight: 700;
          line-height: 160%;
          letter-spacing: -0.28px;
        }
        .point-box {
          margin-top: 7px;
          display: flex;
          align-items: center;
          gap: 4px;
          font-size: 12px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.24px;
        }
        .date {
          color: #666;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: -0.24px;
          margin-top: 7px;
        }
      }
    }
  }
  .show-box {
    padding-bottom: 10px;
    .show-more {
      border-radius: 50px;
      border: 1px solid #e9e9e9;
      background: #fff;
      width: 112px;
      height: 42px;
      margin: 20px auto 40px auto;
      color: #000;
      font-size: 13px;
      font-weight: 500;
      line-height: 40px;
      letter-spacing: -0.26px;
      display: flex;
      align-items: center;
      gap: 6px;
      justify-content: center;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        opacity: 0.6;
      }
    }
  }
  .empty2 {
    width: 100%;
  }
  @media ${(props) => props.theme.mobile} {
  }
`;
