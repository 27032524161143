import React, { useEffect, useState } from "react";
import Layout from "../../componet/Layout";
import { EsgsingleWrapper } from "./styled";
import { useNavigate, useParams } from "react-router-dom";
import { GetChallenge } from "../../service/challeng";
import { Link } from "react-router-dom";
import Footer from "../../componet/Footer";
import { useSetRecoilState } from "recoil";
import { loadingAtom } from "../../store/loading";

const EsgSingle = () => {
  //
  const params = useParams();
  console.log(params);
  const [item, setItem] = useState();
  const nav = useNavigate();
  const setLoading = useSetRecoilState(loadingAtom);

  useEffect(() => {
    setLoading(true);
    GetChallenge({
      joinId: params?.ID,
      isAuth: 1,
    })
      .then((res) => {
        console.log("resssss", res);
        if (res.data.code === "200") {
          setItem(res.data.body);
          console.log(res.data.body);
        } else {
          setItem(null);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [params?.ID]);

  const [hasContent] = useState(true);
  const [activeItems, setActiveItems] = useState({});

  const handleItemClick = (itemId) => {
    setActiveItems((prevItems) => ({
      ...prevItems,
      [itemId]: {
        isActive: true,
        isSuccess: true,
      },
    }));
  };

  return (
    <div>
      <Layout footerType={"none"} headerTitle={item?.postTitle}>
        <EsgsingleWrapper>
          <div className="wrapper">
            <div
              className="main-banner"
              style={{ backgroundImage: `url(${item?.thumbnail})` }}
            >
              <div className="under-menu">
                <div className="Participation">
                  <p className="categories">{item?.cate}</p>
                  <p className="main-title">{item?.postTitle}</p>
                  <div className="number">
                    <img src="/images/esg-point.svg" alt="" />
                    <p>{item?.point}</p>
                    <p className="week">주 {item?.quota}회</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="noti-wrap">
              <div className="date-box">
                <p>
                  <img src="/images/icon-calendar.svg" alt="" />
                  {item?.date}
                </p>
                <p className="week">주 {item?.quota}회, 1주동안</p>
              </div>
              <Link
                to={`/challenge/${item?.challengeId}#notice`}
                className="way-btn"
              >
                <span>인증방법 및 주의사항</span>
                <img src="/images/noti-arrow.svg" alt="" />
              </Link>
            </div>
            <div className="bold-line"></div>
            <div className="situation-wrap">
              <div className="title-wrap">
                <p className="title">인증 현황</p>
                <p className="goals">🔥 현재 달성률 {item?.myRate}%</p>
              </div>
              <div className="situation-list-box">
                <ul className="situation-list">
                  {item?.auth?.length
                    ? item?.auth.map((i, key) => (
                        <li
                          key={key}
                          className={i?.status ? "active" : ""}
                          style={{
                            backgroundImage: i?.status
                              ? `url(${i?.thumbnail})`
                              : "none",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        >
                          {i?.status ? null : "실패"}
                        </li>
                      ))
                    : null}
                  {item?.quota - item?.auth?.length > 0
                    ? Array.apply(
                        null,
                        Array(item?.quota - item?.auth?.length)
                      ).map((i, key) => <li>{key + item?.auth?.length + 1}</li>)
                    : null}
                </ul>
              </div>
              <div className="certified-wrap">
                <ul className="certified-list">
                  <li>
                    <p>인증 완료</p>
                    <h2>{item?.authSuccessNum}</h2>
                  </li>
                  <li>
                    <p>인증 실패</p>
                    <h2>{item?.authFailNum}</h2>
                  </li>
                  <li>
                    <p>남은 인증</p>
                    <h2>{item?.authRemainNum}</h2>
                  </li>
                </ul>
              </div>
              <div className="my">
                <h2 className="title">내 인증샷</h2>
                <a href={`/other/${item?.challengeId}`}>
                  <p>
                    모든 참여자들의 인증
                    <img src="/images/arrow-b.svg" alt="" />
                  </p>
                </a>
              </div>

              {item?.auth?.length ? (
                <div className="myshot-box">
                  {item?.auth.map((i, key) => (
                    <div
                      onClick={() => nav(`/mypage/authlist/${item?.ID}`)}
                      className="myshot"
                      key={key}
                      style={{ backgroundImage: `url(${i?.thumbnail})` }}
                    ></div>
                  ))}
                </div>
              ) : (
                <div className="empty">
                  <img src="/images/icon-x-square.svg" alt="" />
                  <p className="empty-text">아직 등록된 인증이 없습니다.</p>
                </div>
              )}
            </div>
            <div>
              {item && item?.authSuccessNum >= item?.quota ? (
                <div className="certified-btn-gray">
                  <a>챌린지 완주 😎</a>
                </div>
              ) : item ? (
                item?.isTodayAuth ? (
                  <div className="certified-btn-gray">
                    <a>인증완료</a>
                  </div>
                ) : (
                  <div className="certified-btn">
                    <Link to={`/auth/${item?.ID}`}>인증하기</Link>
                  </div>
                )
              ) : null}

              {/* {item ? (
                item?.isTodayAuth ? (
                  <div className="certified-btn-gray">
                    <a>인증완료</a>
                  </div>
                ) : (
                  <div className="certified-btn">
                    <Link to={`/auth/${item?.ID}`}>인증하기</Link>
                  </div>
                )
              ) : null} */}

              {/* <div className="bar"></div> */}
            </div>
          </div>
        </EsgsingleWrapper>
        <Footer />
      </Layout>
    </div>
  );
};

export default EsgSingle;
