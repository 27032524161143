import React, { useEffect, useState } from "react";
import Layout from "../../componet/Layout";
import { ParticipatingWrapper } from "./styled";
import {
  GetChallengeNum,
  GetChallenges,
  GetJoins,
  GetJoinsAll,
} from "../../service/challeng";
import { Link } from "react-router-dom";
import Footer from "../../componet/Footer";
import { useSetRecoilState } from "recoil";
import { loadingAtom } from "../../store/loading";

const MypageAuthList = () => {
  const handleTabClick = (tab) => {
    setSubSlug(tab);
  };

  const setRecoilLoading = useSetRecoilState(loadingAtom);

  const [paged, setPaged] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(8);
  const [slug, setSlug] = useState("alone");
  const [subSlug, setSubSlug] = useState("");
  const [challenges, setChallenges] = useState([]);
  const [total, setTotal] = useState(0);
  const [maxPaged, setMaxPaged] = useState(1);
  const [eachTotal, setEachTotal] = useState({});
  const [cateNum, setCateNum] = useState({});

  // useEffect(() => {
  //   GetJoins({
  //     paged: 1,
  //     postsPerPage: "-1",
  //     subSlug: subSlug,
  //   }).then((res) => {
  //     console.log(res);
  //     if (res.data.code === "200") {
  //       setChallenges(res.data.body.items);
  //       setTotal(res.data.body.total);
  //       setMaxPaged(res.data.body.maxPaged);
  //     } else {
  //       setChallenges(null);
  //       setTotal(0);
  //     }
  //   });
  // }, [subSlug]);

  // useEffect(() => {
  //   if (paged > 1) {
  //     GetChallenges({
  //       paged: paged,
  //       postsPerPage: postsPerPage,
  //       subSlug: subSlug,
  //     })
  //       .then((res) => {
  //         console.log(res);
  //         if (res.data.code === "200") {
  //           setChallenges((p) => [...p, ...res.data.body.items]);
  //           setTotal(res.data.body.total);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // }, [paged]);

  const [year, setYear] = useState("2023");

  useEffect(() => {
    setRecoilLoading(true);
    GetJoinsAll({
      year,
      postsPerPage: 100,
      subSlug,
    })
      .then((res) => {
        if (res.data.code == "200") {
          setChallenges(res.data.body.items);
          setTotal(res.data.body.total);
          setCateNum(res.data.body.cateNum);
        }
        setRecoilLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setRecoilLoading(false);
      });
  }, [year, subSlug]);

  useEffect(() => {
    GetChallengeNum({}).then((res) => {
      console.log(res);
      if (res.data.code === "200") {
        setEachTotal(res.data.body.total);
      }
    });
  }, []);

  return (
    <>
      <Layout
        headerTitle={"완주 횟수"}
        // headerTitle={"참여중인 챌린지"}
        footerType={"none"}
        headerType={"tab"}
      >
        <ParticipatingWrapper>
          <div className="wrapper">
            <ul className="tab-menu">
              <li
                className={subSlug === "" ? "active" : ""}
                onClick={() => handleTabClick("")}
              >
                전체<span>{total ? total : 0}</span>
              </li>
              <li
                className={subSlug === "eco" ? "active" : ""}
                onClick={() => handleTabClick("eco")}
              >
                환경<span>{cateNum?.["환경"]}</span>
              </li>
              <li
                className={subSlug === "culture" ? "active" : ""}
                onClick={() => handleTabClick("culture")}
              >
                조직문화<span>{cateNum?.["조직문화"]}</span>
              </li>
              <li
                className={subSlug === "health" ? "active" : ""}
                onClick={() => handleTabClick("health")}
              >
                건강<span>{cateNum?.["건강"]}</span>
              </li>
            </ul>
            <div className="popular-box">
              <div className="content-wrap-wrapper">
                <select className="select">
                  <option value="2023">2023년</option>
                </select>
                <div className="challenge-content-wrap">
                  {challenges?.length ? (
                    challenges.map((item, key) => (
                      <Link
                        to={`/challenge/${item?.ID}`}
                        className="challenge-content-box"
                      >
                        <div className="challenge-content">
                          <div
                            className="content-img"
                            style={{
                              backgroundImage: `url(${item.thumbnail})`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          >
                            <div className="noti">주 {item.quota}회</div>
                          </div>
                          <div className="content-text-box">
                            <p className="categories">{item.cate}</p>
                            <p className="content-title">{item.postTitle}</p>
                            <div className="point-box">
                              <img src="/images/esg-point.svg" alt="" />
                              {item.point}
                            </div>
                            <p className="date">{item?.successNum}회 완료</p>
                            {/* <p className="date">{item.date}</p> */}
                          </div>
                        </div>
                      </Link>
                    ))
                  ) : challenges ? null : (
                    <div className="empty empty2">
                      <img src="/images/icon-x-square.svg" alt="" />
                      <p className="empty-text">진행중인 챌린지가 없습니다.</p>
                    </div>
                  )}
                </div>
              </div>
              {paged >= maxPaged ? null : (
                <div className="show-box">
                  <div
                    onClick={() => setPaged((p) => p + 1)}
                    className="show-more"
                  >
                    더보기
                    <img src="/images/esg-plus-icon.svg" alt="" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </ParticipatingWrapper>
        <Footer />
      </Layout>
    </>
  );
};

export default MypageAuthList;
