import React, { useState, useEffect } from "react";
import Layout from "../../componet/Layout";
import { GetChallenges, GetJoins } from "../../service/challeng";
import { AccreditWrapper } from "./styled";
import { Link, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { loadingAtom } from "../../store/loading";

const EsgAuthList = () => {
  const [paged, setPaged] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(8);
  const [slug, setSlug] = useState("alone");
  const [subSlug, setSubSlug] = useState("");
  const [challenges, setChallenges] = useState([]);
  const [total, setTotal] = useState(0);
  const nav = useNavigate();
  const setLoading = useSetRecoilState(loadingAtom);

  useEffect(() => {
    setLoading(true);
    GetJoins({
      paged: paged,
      postsPerPage: 100,
      isMine: 1,
    })
      .then((res) => {
        console.log(res);
        if (res.data.code === "200") {
          setChallenges(res.data.body.items);
          setTotal(res.data.body.total);
        } else {
          setChallenges(null);
          setTotal(0);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [subSlug]);

  const [completed, setCompleted] = useState(false);

  const handleComplete = () => {
    setCompleted(true);
  };

  return (
    <>
      <Layout headerType={"sub"} headerTitle={"인증하기"} footerType={"camera"}>
        <AccreditWrapper>
          <div className="wrapper">
            {challenges?.length ? (
              <h2 className="ing-title">
                진행중인 챌린지<span>{total}</span>
              </h2>
            ) : (
              <h2 className="nell-ing-title">
                진행중인 챌린지<span>{total}</span>
              </h2>
            )}

            {challenges?.length ? (
              <div className="ing-wrap">
                {challenges.map((item, index) => (
                  <div className="ing-card" key={index}>
                    <div className="accredit">
                      <div
                        style={{
                          position: "relative",
                          backgroundImage: `url(${item.thumbnail})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          width: 40,
                          height: 40,
                          borderRadius: 40,
                        }}
                        className="accredit-img"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="-1 -1 34 34"
                        >
                          <circle
                            cx="16"
                            cy="16"
                            r="15.9155"
                            class="progress-bar__background"
                          />

                          <circle
                            cx="16"
                            cy="16"
                            r="15.9155"
                            stroke-dashoffset={100 - item?.rate}
                            class="progress-bar__progress 
                 js-progress-bar"
                          />
                        </svg>
                      </div>
                      <div>
                        <div
                          onClick={() => nav(`/mypage/esg/${item?.ID}`)}
                          className="accredit-title"
                        >
                          <p>{item?.postTitle}</p>
                          <img src="/images/accredit-arrow.svg" alt="" />
                        </div>
                        <div className="number">
                          <img src="/images/esg-point.svg" alt="" />
                          <p>{item?.point}</p>
                          <p className="week">주 {item?.quota}회</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      {item?.completed ? (
                        <div className="completed-btn">챌린지 완주 😎</div>
                      ) : (
                        <div>
                          <div className="accredit-btn-box">
                            <span>📍 남은 인증 횟수 {item?.remainNum}회</span>
                            <span>🔥 현재 달성률 {item?.rate}%</span>
                          </div>
                          <div className="accredit-btn">
                            {item?.isTodayAuth ? (
                              <a className="done">오늘 인증 완료 !</a>
                            ) : (
                              <Link
                                to={`/auth/${item?.ID}`}
                                onClick={handleComplete}
                              >
                                인증하기
                              </Link>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : challenges ? null : (
              <div className="empty">
                <img src="/images/icon-x-square.svg" alt="" />
                <p className="empty-text">진행중인 챌린지가 없습니다.</p>
              </div>
            )}
          </div>
        </AccreditWrapper>
      </Layout>
    </>
  );
};

export default EsgAuthList;
