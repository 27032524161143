import React, { useState } from "react";
import { Wrapper } from "./styled";
import { ApplyChallenge, GetChallenge } from "../../service/challeng";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { insertAuth } from "../../service/challeng";

const Footer = ({ type }) => {
  const params = useParams();
  console.log(params);

  if (!type) {
    return (
      <Wrapper>
        <div className="esg-footer">
          <ul className="footer-list">
            <li>
              <a href="/">
                <img src="/images/footer-home.svg" alt="" />
              </a>
            </li>
            <li>
              <a href="/esgauthlist">
                <img src="/images/footer-camera.svg" alt="" />
              </a>
            </li>
            <li>
              <a href="/rewards">
                <img src="/images/footer-bookmark.svg" alt="" />
              </a>
            </li>
            <li>
              <a href="/mypage">
                <img src="/images/footer-person.svg" alt="" />
              </a>
            </li>
          </ul>
          <div className="bar"></div>
        </div>
      </Wrapper>
    );
  }
  if (type === "camera") {
    return (
      <Wrapper>
        <div className="esg-footer">
          <ul className="footer-list">
            <li>
              <a href="/">
                <img src="/images/footer-home-h.svg" alt="" />
              </a>
            </li>
            <li>
              <a>
                <img src="/images/footer-camera-fill.svg" alt="" />
              </a>
            </li>
            <li>
              <a href="/rewards">
                <img src="/images/footer-bookmark.svg" alt="" />
              </a>
            </li>
            <li>
              <a href="/mypage">
                <img src="/images/footer-person.svg" alt="" />
              </a>
            </li>
          </ul>
          <div className="bar"></div>
        </div>
      </Wrapper>
    );
  }
  if (type === "reward") {
    return (
      <Wrapper>
        <div className="esg-footer">
          <ul className="footer-list">
            <li>
              <a href="/">
                <img src="/images/footer-home-h.svg" alt="" />
              </a>
            </li>
            <li>
              <a href="/esgauthlist">
                <img src="/images/footer-camera.svg" alt="" />
              </a>
            </li>
            <li>
              <a>
                <img src="/images/footer-bookmark-fill.svg" alt="" />
              </a>
            </li>
            <li>
              <a href="/mypage">
                <img src="/images/footer-person.svg" alt="" />
              </a>
            </li>
          </ul>
          <div className="bar"></div>
        </div>
      </Wrapper>
    );
  }
  if (type === "my") {
    return (
      <Wrapper>
        <div className="esg-footer">
          <ul className="footer-list">
            <li>
              <a href="/">
                <img src="/images/footer-home-h.svg" alt="" />
              </a>
            </li>
            <li>
              <a href="/esgauthlist">
                <img src="/images/footer-camera.svg" alt="" />
              </a>
            </li>
            <li>
              <a href="/rewards">
                <img src="/images/footer-bookmark.svg" alt="" />
              </a>
            </li>
            <li>
              <a>
                <img src="/images/footer-person-fill.svg" alt="" />
              </a>
            </li>
          </ul>
          <div className="bar"></div>
        </div>
      </Wrapper>
    );
  }
  if (type === "none") {
    return (
      <Wrapper>
        <div className="none-footer">
          <div className="bar"></div>
        </div>
      </Wrapper>
    );
  }
};

export default Footer;
