import React, { useEffect, useState } from "react";
import Layout from "../../componet/Layout";
import { CertifiedWrapper } from "./styled";
import {
  GetAuthByChallenge,
  GetAuthIsMine,
  GetAuthSingle,
  InsertLike,
} from "../../service/challeng";
import { useParams } from "react-router-dom";
import { insert } from "../../service/commnet";
import Footer from "../../componet/Footer";
import Alert from "../../componet/Alert";
import { useRecoilState, useSetRecoilState } from "recoil";
import { userData } from "../../store/users";
import { loadingAtom } from "../../store/loading";

const OtherFeed = () => {
  //
  const params = useParams();
  const [paged, setPaged] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(9);
  const [maxPaged, setMaxPaged] = useState(1);
  const [user, setUser] = useRecoilState(userData);
  const setRecoilLoading = useSetRecoilState(loadingAtom);

  const [active, setActive] = useState(false);
  const handleButtonClick = () => {
    setActive(!active);
  };

  function getPageOffsetsByIds(className) {
    const elements = document.getElementsByClassName(className);
    const pageOffsets = [];

    for (const element of elements) {
      const rect = element.getBoundingClientRect();
      if (rect?.y) {
        const offsetY = rect.top + window.pageYOffset;
        window.scrollTo({
          top: offsetY,
          behavior: "smooth", // You can use 'auto' or 'smooth'
        });
        return null;
      }
    }
  }

  const [commentShow, setCommentShow] = useState(false);

  const [profileShow, setProfileShow] = useState(false);

  const ShowProfile = () => {
    console.log(123);
    setProfileShow((p) => !p);
  };

  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [call, setCall] = useState(false);
  const [once, setOnce] = useState(false);

  useEffect(() => {
    setRecoilLoading(true);
    GetAuthByChallenge({
      challengeId: params?.ID,
      paged: 1,
      postsPerPage: postsPerPage,
    })
      .then((res) => {
        console.log(res);
        if (res.data.code === "200") {
          setItems(res.data.body.items);
          setMaxPaged(res.data.body.maxPaged);
          setTotal(res.data.body.total);
          setOnce((p) => !p);
        } else {
        }
        setRecoilLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setRecoilLoading(false);
      });
  }, [call]);

  useEffect(() => {
    if (paged > 1) {
      GetAuthByChallenge({
        challengeId: params?.ID,
        paged: paged,
        postsPerPage: postsPerPage,
      })
        .then((res) => {
          console.log(res);
          if (res.data.code === "200") {
            setItems((p) => [...p, ...res.data.body.items]);
            setTotal(res.data.body.total);
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [paged]);

  useEffect(() => {
    if (window.location.hash && items) {
      setTimeout(() => {
        getPageOffsetsByIds(window.location.hash.replace("#", ""));
      }, [50]);
    }
  }, [once, window.location.hash]);

  const [expandedItem, setExpandedItem] = useState(null);

  const handleAccordionClick = (index) => {
    setExpandedItem(index === expandedItem ? null : index);
  };

  const [comment, setComment] = useState("");

  const [authId, setAuthId] = useState("");

  const commentSubmit = (authId) => {
    if (!comment) {
      return;
    }
    setAuthId(authId);
    toggle3();
  };

  const submit = () => {
    setRecoilLoading(true);
    insert({
      authId: authId,
      content: comment,
    })
      .then((res) => {
        // setCall((p) => !p);
        getSingle(authId);
        toggle4();
        setComment("");
        console.log(res);
        setRecoilLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setRecoilLoading(false);
      });
  };

  const getSingle = (authId) => {
    GetAuthSingle({
      authId: authId,
    })
      .then((res) => {
        console.log(res);
        if (res.data.code === "200") {
          setItems(res.data.body.items);
          setTotal(res.data.body.total);

          const targetPropertyValue = authId;
          const newObject = res.data.body;

          const index = items.findIndex(
            (obj) => obj.ID === targetPropertyValue
          );
          if (index !== -1) {
            const updatedArray = [...items];
            updatedArray[index] = newObject;
            setItems(updatedArray);
          }
        } else {
          setItems(null);
          setTotal(0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DEFAULT_USER_IMAGE = "/images/user-null.png";

  const DoLike = (authId) => {
    InsertLike({
      authId: authId,
    })
      .then((res) => {
        getSingle(authId);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [image, setImage] = useState("");
  const [show, setShow] = useState(false);
  const showImage = (thumbnail) => {
    setImage(thumbnail);
    setShow(true);
  };

  const [loading, setLoading] = useState(false);

  const handleScroll = () => {
    if (!loading) {
      console.log(1);
      let offsetHeight = document.documentElement.offsetHeight;
      let scrollTop = document.documentElement.scrollTop;
      let innerHeight = window.innerHeight;
      console.log(offsetHeight - (innerHeight + scrollTop));
      console.log(paged < maxPaged);
      if (offsetHeight - (innerHeight + scrollTop) < 300 && paged < maxPaged) {
        setLoading(true);
        setPaged((p) => p + 1);
        console.log(2);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const [active3, setActive3] = useState(false);
  const toggle3 = () => {
    setActive3((p) => !p);
  };

  const [active4, setActive4] = useState(false);
  const toggle4 = () => {
    setActive4((p) => !p);
  };

  return (
    <>
      <Layout headerTitle={"참여자 인증피드"} footerType={"none"}>
        <CertifiedWrapper>
          <div className="wrapper">
            <div className="popular-box">
              {items?.length
                ? items.map((item, index) => (
                    <div className="certified-wrap" key={index}>
                      <div id="tmp" className={item.ID}></div>
                      <div className="my-info">
                        <div className="left-info">
                          <div
                            className="user-img"
                            style={{
                              backgroundImage: `url(${
                                item?.authorAvatar || DEFAULT_USER_IMAGE
                              })`,
                            }}
                            // onClick={ShowProfile}
                          ></div>
                          <div>
                            <p>{item.authorName}</p>
                            <span>{item.date}</span>
                          </div>
                        </div>
                        {/* <div>
                          <img
                            src="/images/recycle.svg"
                            alt=""
                            className="icon-recycle"
                          />
                        </div> */}
                      </div>
                      <div
                        className="photo"
                        style={{
                          backgroundImage: `url(${item.thumbnail})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      >
                        <div className="like-box">
                          <p onClick={() => DoLike(item?.ID)}>
                            {item?.isLike ? (
                              <img
                                src="/images/like-active.svg"
                                alt=""
                                className="like-btn"
                              />
                            ) : (
                              <img
                                src="/images/like.svg"
                                alt=""
                                className="like-btn"
                              />
                            )}
                            좋아요 {item?.likeCount ? item?.likeCount : ""}
                          </p>
                          <button onClick={() => handleAccordionClick(index)}>
                            <img src="/images/chat.svg" alt="" />
                            댓글 <span>{item?.commentCount}</span>
                          </button>
                        </div>
                        <img
                          onClick={() => showImage(item.thumbnail)}
                          className="r"
                          src="/images/freat.png"
                        />
                      </div>
                      <div className="photo-content">
                        <p className="photo-title">{item.challengeTitle}</p>
                        <p
                          className="photo-text"
                          dangerouslySetInnerHTML={{ __html: item?.content }}
                        ></p>
                      </div>
                      {index == expandedItem ? (
                        <div className="comment-box">
                          {item?.comments?.length
                            ? item?.comments?.map((comment, i) => (
                                <div key={i}>
                                  <div className="user-info">
                                    <div
                                      onClick={ShowProfile}
                                      className="user"
                                      style={{
                                        backgroundImage: `url(${comment?.author?.thumbnail})`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                      }}
                                    ></div>
                                    <p className="name">
                                      {comment?.author?.name}
                                    </p>
                                  </div>
                                  <p className="comment">{comment?.content}</p>
                                  <p className="date">{comment?.date}</p>
                                </div>
                              ))
                            : null}
                          <div className="chat-box">
                            <div
                              className="my-profile"
                              style={{
                                backgroundImage: `url(${
                                  user?.avatar || DEFAULT_USER_IMAGE
                                })`,
                              }}
                            ></div>
                            <input
                              type="text"
                              placeholder="댓글을 입력해 주세요"
                              onChange={(e) => setComment(e.target.value)}
                              value={comment}
                            />
                            <img
                              src="/images/prime_send.svg"
                              alt=""
                              className="send"
                              onClick={() => commentSubmit(item?.ID)}
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ))
                : null}
            </div>
            {/* 댓글창 */}
            {/* <div className="comment-box">
              {items.map((comment, index) => (
                <div key={index}>
                  <div className="user-info">
                    <div
                      onClick={ShowProfile}
                      className="user"
                      style={{
                        backgroundImage: `url(/images/user.png)`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></div>
                    <p className="name">{comment.name}</p>
                  </div>
                  <p className="comment">{comment.content}</p>
                  <p className="date">{comment.date}</p>
                </div>
              ))}
              <div className="chat-box">
                <div
                  className="my-profile"
                  style={{
                    backgroundImage: `url(/images/esg-user.png)`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></div>
                <input type="text" placeholder="댓글을 입력해 주세요" />
                <img src="/images/prime_send.svg" alt="" className="send" />
              </div>
            </div> */}
            <div
              className={profileShow ? "other-profile active" : "other-profile"}
            >
              <div className="bar" onClick={ShowProfile}></div>
              <div className="my-info">
                <div className="user-img"></div>
                <div>
                  <p>홍길동</p>
                  <span>
                    <img src="/images/esg-point.svg" alt="" />
                    13,280
                  </span>
                </div>
              </div>
              <div className="situation-wrap">
                <a href="/">
                  <p>참여중</p>
                  <h5>2</h5>
                </a>
                <a href="/">
                  <p>인증</p>
                  <h5>34</h5>
                </a>
              </div>
              <div className="profile-btn">
                <a href="/">
                  <img src="/images/person.svg" alt="" />
                  프로필 보기
                </a>
              </div>
            </div>
          </div>
          {show ? (
            <div className="modal" onClick={() => setShow(false)}>
              <div className="closebtn">
                <img src={"/images/closebtn.png"} />
              </div>

              <div style={{ backgroundImage: `url(${image})` }} />
            </div>
          ) : null}
          <Alert
            active={active3}
            toggle={toggle3}
            title="댓글을 작성하시겠습니까?"
            event={submit}
          />
          <Alert
            active={active4}
            toggle={toggle4}
            title="작성이 완료되었습니다."
            oneEvent={true}
          />
        </CertifiedWrapper>
        <Footer />
      </Layout>
    </>
  );
};

export default OtherFeed;
