import React, { useEffect, useState } from "react";
import { Wrapper } from "./styled";
import { useNavigate } from "react-router-dom";

const Header = ({ type, headerTitle }) => {
  const [scrolled, setScrolled] = useState(false);
  const nav = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setScrolled(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [activeItem, setActiveItem] = useState("전체");

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  const [selectedTab, setSelectedTab] = useState("전체");
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };
  const GoBack = () => {
    window.history.back();
  };

  if (type == "tab") {
    return (
      <Wrapper scrolled={scrolled}>
        <div className="wrapper">
          <header>
            <div
              className={
                type == "tab"
                  ? "esg-header esg-header2 tab"
                  : "esg-header esg-header2"
              }
            >
              <div className="bar"></div>
              <div className="top-header">
                <div className="logo-box2">
                  <img
                    src="/images/header-arrow.svg"
                    alt=""
                    className="arrow"
                    onClick={GoBack}
                  />
                  <h2>{headerTitle}</h2>
                </div>
              </div>
            </div>
          </header>
        </div>
      </Wrapper>
    );
  }

  if (!type) {
    return (
      <Wrapper scrolled={scrolled}>
        <div className="wrapper">
          <header>
            <div className="esg-header esg-header2">
              <div className="bar"></div>
              <div className="top-header">
                <div className="logo-box2">
                  <img
                    src="/images/header-arrow.svg"
                    alt=""
                    className="arrow"
                    onClick={GoBack}
                  />
                  <h2>{headerTitle}</h2>
                </div>
              </div>
            </div>
          </header>
        </div>
      </Wrapper>
    );
  }
  if (type === "home") {
    return (
      <Wrapper scrolled={scrolled}>
        <div className="wrapper">
          <header>
            <div className="main-header home">
              <div className="esg-header esg-header3">
                <div className="bar"></div>
                <div className="top-header">
                  <div onClick={() => nav(`/`)} className="logo-box">
                    <img src="/images/esg-logo.png" alt="" />
                    <h2>
                      <span>KBDS ESG</span>챌린지
                    </h2>
                    {/* <p className="test">( 테스트 기간 ~ 9.13 )</p> */}
                  </div>
                  {/* <div className="bell">
                    <img src="/images/bell.svg" alt="" />
                    <div className="noti">+3</div>
                  </div> */}
                </div>
              </div>
            </div>
          </header>
        </div>
      </Wrapper>
    );
  }

  if (type === "sub") {
    return (
      <Wrapper scrolled={scrolled}>
        <div className="wrapper">
          <header>
            <div className="esg-header">
              <div className="bar"></div>
              <div className="logo-box">
                <h2>{headerTitle}</h2>
              </div>
            </div>
          </header>
        </div>
      </Wrapper>
    );
  }
  if (type === "none") {
    return (
      <Wrapper scrolled={scrolled}>
        <div className="wrapper">
          <header>
            <div className="esg-header esg-header5">
              <div className="bar"></div>
              <div className="logo-box">
                <h2>{headerTitle}</h2>
              </div>
            </div>
          </header>
        </div>
      </Wrapper>
    );
  }
};

export default Header;
