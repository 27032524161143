import styled from "styled-components";

export const Wrapper = styled.div`
  .esg-footer {
    border-top: 1px solid #eee;
    padding: 12px 40px;
    background-color: #fff;
    position: fixed;
    z-index: 100;
    max-width: 500px;
    width: 100%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
    .footer-list {
      display: flex;
      justify-content: space-between;
    }
    /* .bar {
      border-radius: 30px;
      border-bottom: 5px solid #000;
      width: 130px;
      margin: 29px auto 0 auto;
    } */
  }
  .btn-box {
    box-shadow: 0px 3px 12px 0px rgba(255, 255, 255, 0.03);
    max-width: 500px;
    width: 100%;
    .participate-btn {
      border-radius: 10px;
      background: #ffbc00;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 50px;
      width: 100%;
      height: 52px;
      cursor: pointer;
      transition: all 0.3s;
      font-family: Noto Sans KR;
      display: block;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .certified-btn {
    a {
      border-radius: 10px;
      background: #ffbc00;
      display: block;
      width: 100%;
      height: 50px;
      color: #000;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 48px;
      transition: all 0.3s;
      text-decoration: none;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .btn-box {
    display: flex;
    gap: 8px;
    button {
      width: 50%;
      height: 50px;
      color: #000;
      text-align: center;
      font-family: Noto Sans KR;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-radius: 10px;
      background: #ffbc00;
      border: none;
      transition: all 0.3s;
      &:hover {
        opacity: 0.8;
      }
    }
    .back-btn {
      border: 1px solid #e9e9e9;
      background: #f7f7f7;
    }
  }
  .esg-footer-btn {
    padding: 12px 20px 8px 20px;
  }
  .none-footer {
    display: none;
  }
`;
