import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  .wrapper {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
  }
  .main-header {
    height: 104px;
    border-bottom: 1px solid #eee;
    max-width: 500px;
    width: 100%;
  }
  .home {
    height: 72px;
    border: none;
  }
  .esg-header {
    padding: 12px 20px;
    position: fixed;
    top: 0;
    z-index: 1000;
    background-color: #fff;
    max-width: 500px;
    width: 100%;
    box-sizing: border-box;
    height: 54px;
    border-bottom: ${(props) =>
      props.scrolled ? "1px solid #e9e9e9" : "none"};
    box-shadow: ${(props) =>
      props.scrolled ? "0px 3px 12px 0px rgba(0, 0, 0, 0.03)" : "none"};

    h2 {
      font-family: KBFG Display;
      font-size: 20px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.4px;
    }
  }
  .esg-header2 {
    height: 78px;
  }
  .esg-header2.tab {
    height: 112px;
  }
  .esg-header3 {
    /* height: 104px; */
    height: 72px;
    padding: 12px 28px 15px 20px;
    .top-header {
      margin-top: 10px;
    }
    border-bottom: ${(props) =>
      props.scrolled ? "1px solid #e9e9e9" : "1px solid #e9e9e9"};
  }
  .esg-header4 {
    height: 131px;
    border-bottom: ${(props) =>
      props.scrolled ? "1px solid #e9e9e9" : "1px solid #e9e9e9"};
  }
  .esg-header5 {
    display: none;
  }
  .bar {
    width: 56px;
    height: 4px;
    background: #e9ecef;
    border-radius: 10px;
    margin: 0 auto;
  }
  .top-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 14px;
  }
  .test {
    color: #f00;
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: -0.39px;
  }
  .logo-box {
    display: flex;
    align-items: center;
    gap: 10px;
    img {
      width: 24px;
      height: 24px;
    }
    h2 {
      font-family: Noto Sans KR;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.6;
      letter-spacing: -0.54px;
      display: flex;
      gap: 6px;
      margin-top: 10px;

      span {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
  .logo-box2 {
    display: flex;
    align-items: center;
    width: 100%;
    h2 {
      font-family: Noto Sans KR;
      font-size: 18px;
      font-weight: 600;
      line-height: 160%;
      letter-spacing: -0.54px;
      text-align: center;
      width: 100%;
    }
    img {
      cursor: pointer;
      padding-right: 20px;
      position: absolute;
    }
  }
  .under-header {
    display: flex;
    position: absolute;
    top: 73px;
    gap: 40px;
    margin-left: 10px;

    li {
      font-family: Noto Sans KR;
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.7px;
      border-bottom: 2px solid transparent;
      padding-bottom: 12px;
      &.active {
        border-bottom: 2px solid #000;
        a {
          color: #000;
        }
      }
      a {
        color: #9b9b9b;
        text-decoration: none;
        padding-bottom: 12px;
        transition: all 0.3s;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .bell {
    position: relative;
    display: flex;
    .noti {
      width: 23px;
      height: 16px;
      background-color: #fb4700;
      border-radius: 38px;
      position: absolute;
      color: #fff;
      font-size: 10px;
      font-weight: 700;
      line-height: 16px;
      text-align: center;
      left: 15px;
    }
  }
  .tab-menu {
    display: flex;
    align-items: center;
    gap: 40px;
    padding-top: 40px;
    li {
      color: #9b9b9b;
      font-family: Noto Sans KR;
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.7px;
      border-bottom: 2px solid transparent;
      padding-bottom: 10px;
      cursor: pointer;
      display: flex;
      gap: 3px;
      align-items: center;
      &.active {
        color: #000;
        border-bottom: 2px solid #000;
      }
      &:hover {
        opacity: 0.8;
      }
      &.active span {
        border-radius: 30px;
        background: #000;
        width: 24px;
        height: 20px;
        display: inline-block;
        color: #fff;
        font-family: Noto Sans KR;
        font-size: 11px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.55px;
        text-align: center;
      }
    }
  }
  @media ${(props) => props.theme.mobile} {
    .bar {
      background-color: #fff;
    }
  }
`;
