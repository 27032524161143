import styled from "styled-components";

export const AccreditWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  .wrapper {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
    padding-bottom: 100px;

    /* $progress-bar-stroke-width: 1.8;
    $progress-bar-size: 300px; */

    $progress-bar-stroke-width: 1.8;
    $progress-bar-size: 48px;

    svg {
      height: 56px;
      transform: rotate(-90deg);
      width: 56px;
    }

    .progress-bar__background {
      fill: none;
      stroke: #fff;
      stroke-width: 2px;
    }

    .progress-bar__progress {
      fill: none;
      stroke: #fff;
      stroke: #ffbc00;
      stroke-dasharray: 100 100;
      /* stroke-dashoffset: 30; */
      stroke-linecap: round;
      stroke-width: 2px;
      transition: stroke-dashoffset 1s ease-in-out;
    }
  }
  a {
    text-decoration: none;
    color: #000;
  }
  .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 160%;
    letter-spacing: -0.36px;
  }

  .ing-title {
    padding: 80px 20px 0 20px;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.36px;
    margin-bottom: 18px;
    margin-top: 5px;
    span {
      color: #ffbc00;
      margin-left: 8px;
    }
  }
  .nell-ing-title {
    padding: 80px 20px 0 20px;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.36px;
    margin-bottom: 18px;
    margin-top: 5px;
    span {
      color: #cccccc;
      margin-left: 8px;
    }
  }
  .ing-wrap {
    padding: 0 20px;
    margin-top: 10px;
    .ing-card {
      border-radius: 10px;
      border: 1px solid #e9e9e9;
      padding: 20px;
      margin-top: 10px;
      .accredit {
        display: flex;
        align-items: center;
        gap: 20px;

        .accredit-img {
          position: relative;
          /* background-image: url(/images/accredit01.png); */
          background-position: center;
          svg {
            position: absolute;
            top: -8px;
            left: -8px;
          }
        }
        .accredit-title {
          display: flex;
          align-items: center;
          gap: 6px;
          cursor: pointer;
          p {
            font-family: Noto Sans KR;
            font-size: 16px;
            font-weight: 700;
            line-height: 150%;
            letter-spacing: -0.32px;
          }
          img {
            position: relative;
            top: -1px;
          }
        }
        .number {
          display: flex;
          align-items: center;
          margin-top: 6px;
          img {
            margin-right: 4px;
          }
          p {
            font-family: Noto Sans KR;
            font-size: 12px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.24px;
            margin-right: 9px;
          }
          .week {
            position: relative;
            margin-left: 8px;
            padding-left: 3px;
            &:after {
              position: absolute;
              content: "";
              top: 50%;
              transform: translateY(-50%);
              left: -8px;
              width: 3px;
              height: 3px;
              background-color: #999;
              border-radius: 50%;
            }
          }
        }
      }
      .accredit-btn-box {
        margin-top: 15px;
        display: flex;
        gap: 10px;
        span {
          font-family: Noto Sans KR;
          font-size: 12px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.24px;
          border-radius: 4px;
          background: rgba(255, 188, 0, 0.12);
          padding: 6px 8px 7px 8px;
        }
      }
      .completed-btn {
        margin-top: 14px;
        width: 100%;
        border-radius: 10px;
        background: #fff;
        color: #666;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        line-height: 44px;
        display: block;
        height: 46px;
        transition: all 0.3s;
        border: 1px solid #eee;
      }
      .accredit-btn {
        margin-top: 14px;
        a {
          width: 100%;
          border-radius: 10px;
          background: #000;
          color: #fff;
          text-align: center;
          font-size: 14px;
          font-weight: 700;
          line-height: 44px;
          display: block;
          height: 46px;
          transition: all 0.3s;
          border: none;
          &:hover {
            opacity: 0.8;
          }
        }
        .done {
          border: 1px solid #e9e9e9;
          background: #f7f7f7;
          color: #999;
        }
      }
      .accredit-btn.clicked button {
        border: 1px solid #e9e9e9;
        background: #f7f7f7;
        color: #999;
      }
    }
  }

  @media ${(props) => props.theme.mobile} {
  }
`;
