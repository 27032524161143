import React, { useState } from "react";
import { Wrapper } from "./styled";

const Alert = ({ active, toggle, title, event, oneEvent }) => {
  //
  const confirm = () => {
    if (event) {
      event();
    }
    toggle();
  };

  return (
    <>
      <Wrapper active={active}>
        <div className="overlay" onClick={toggle}></div>
        <div className="container">
          <h4 className="title">알림</h4>
          <p>{title}</p>
          <div className="btn-wrap">
            {oneEvent ? (
              <div className="btn p" onClick={confirm}>
                확인
              </div>
            ) : (
              <>
                <div className="btn close" onClick={toggle}>
                  취소
                </div>
                <div className="btn" onClick={confirm}>
                  확인
                </div>
              </>
            )}
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default Alert;
