import styled from "styled-components";

export const GaugeWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  .wrapper {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
    padding-bottom: 30px;
  }
  a {
    text-decoration: none;
    color: #000;
  }
  .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 160%;
    letter-spacing: -0.36px;
  }

  .select-wrap {
    padding: 80px 20px 10px 20px;
    select {
      width: 100%;
      border-radius: 10px;
      border: 1px solid #e9e9e9;
      background: #fff;
      padding: 11px 20px 13px 20px;
      color: #000;
      font-family: Noto Sans KR;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.28px;
      appearance: none;
      -webkit-appearance: none;
      background-image: url(/images/esg-select-arrow.svg);
      background-repeat: no-repeat;
      background-position: top 50% right 20px;
    }
  }
  .btn-wrap {
    border-bottom: 1px solid #eeeeee;
    padding: 0 30px;
    .btn-list {
      border-radius: 10px;
      display: flex;
      height: 46px;
      color: #666;
      font-size: 14px;
      font-weight: 700;
      line-height: 44px;
      .accomplishment,
      .participation {
        width: 50%;
        cursor: pointer;
        text-align: center;
        transition: all 0.3s;
        &.active {
          color: #000;
          border-bottom: 2px solid #000;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .earth-wrap {
    margin-top: 60px;
    padding: 0 30px;
    .noti {
      border-radius: 20px 20px 2px 20px;
      background: #000;
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: -0.36px;
      display: inline-block;
      height: 48px;
      padding: 0 18px;
      line-height: 48px;
    }
    .img-box {
      max-width: 250px;
      width: 100%;
      height: 300px;
      margin: 0 auto;
      position: relative;
      top: -60px;
      .earth {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    p {
      border-radius: 16px;
      background: #000;
      color: #fff;
      text-align: center;
      font-size: 13px;
      font-weight: 500;
      max-width: 254px;
      width: 100%;
      margin: 0 auto;
      top: -45px;
      position: relative;
      height: 44px;
      line-height: 44px;
      &:after {
        position: absolute;
        content: "";
        background-image: url(/images/Triangle-top.svg);
        width: 12px;
        height: 10px;
        top: -9px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .img-box2 {
      max-width: 250px;
      width: 100%;
      height: 300px;
      margin: 0 auto;
      position: relative;
      /* top: -90px; */

      .earth {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    p {
      border-radius: 16px;
      background: #000;
      color: #fff;
      text-align: center;
      font-size: 13px;
      font-weight: 500;
      max-width: 254px;
      width: 100%;
      margin: 0 auto;
      top: -48px;
      position: relative;
      height: 44px;
      line-height: 44px;
      &:after {
        position: absolute;
        content: "";
        background-image: url(/images/Triangle-top.svg);
        width: 12px;
        height: 10px;
        top: -9px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .f-text {
      top: -80px;
    }
    .l-text {
      top: -28px;
    }
    .e-text {
      top: -34px;
    }
  }

  .participation-content {
    padding: 0 20px;
    .total {
      font-size: 12px;
      font-weight: 700;
      letter-spacing: -0.24px;
      font-family: Noto Sans KR;
      margin-top: 24px;
    }
    .challenge-content-wrap {
      display: flex;
      flex-wrap: wrap;
      gap: 0 10px;

      .challenge-content-box {
        width: calc((100% - 10px) / 2);
        margin-top: 10px;
      }
      .challenge-content {
        margin-bottom: 10px;
        position: relative;

        &:hover .dim {
          opacity: 1;
        }
        .dim {
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          border-radius: 10px;
          background: rgba(0, 0, 0, 0.7);
          opacity: 0;
          transition: all 0.3s;
          p {
            color: #fff;
            font-size: 12px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.24px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .content-img {
          width: 100%;
          height: 0;
          padding-bottom: 78%;
          background-image: url(/images/popular-challenge01.png);
          border-radius: 10px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          position: relative;
          .noti {
            border-radius: 4px;
            background: rgba(0, 0, 0, 0.56);
            display: inline-block;
            color: #fff;
            font-size: 11px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.22px;
            padding: 4px 6px 5px 6px;
            position: absolute;
            top: 8px;
            right: 14px;
          }
        }
        .content-text-box {
          margin-top: 11px;
          .categories {
            color: #666;
            font-size: 12px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.24px;
          }
          .content-title {
            font-size: 14px;
            font-weight: 700;
            line-height: 160%;
            letter-spacing: -0.28px;
            margin-top: 3px;
          }
          .point-box {
            /* margin-top: 7px; */
            display: flex;
            align-items: center;
            gap: 4px;
            font-size: 12px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.24px;
            margin-top: 5px;
          }
          .date {
            color: #666;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: -0.24px;
            margin-top: 7px;
          }
        }
      }
    }
    .show-box {
      padding-bottom: 10px;
      .show-more {
        border-radius: 50px;
        border: 1px solid #e9e9e9;
        background: #fff;
        width: 112px;
        height: 42px;
        margin: 20px auto 40px auto;
        color: #000;
        font-size: 13px;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: -0.26px;
        display: flex;
        align-items: center;
        gap: 6px;
        justify-content: center;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
  @media ${(props) => props.theme.mobile} {
  }
`;
