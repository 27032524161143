import styled from "styled-components";

export const EsgsingleWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  .wrapper {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
    padding-bottom: 100px;
  }
  a {
    text-decoration: none;
    color: #000;
  }
  .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 160%;
    letter-spacing: -0.4px;
  }

  .main-banner {
    /* background-image: url(/images/health.png); */
    width: 100%;
    height: 0;
    padding-bottom: 76%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    /* top: 78px; */
    margin-top: 78px;
    &:after {
      position: absolute;
      content: "";
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.8) 100%
      );
      width: 100%;
      height: 136px;
      bottom: 0;
    }
    .under-menu {
      color: #fff;
      padding: 20px;
      position: absolute;
      bottom: 0;
      width: 100%;
      z-index: 1;
      .Participation {
        .categories {
          color: #fff;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -0.28px;
          margin-bottom: 4px;
          position: relative;
          top: -5px;
        }
        .main-title {
          font-size: 20px;
          font-weight: 700;
          line-height: 160%;
          letter-spacing: -0.4px;
        }
      }
    }
    .number {
      display: flex;
      align-items: flex-start;
      margin-top: 8px;
      img {
        margin-right: 4px;
      }
      p {
        font-family: Noto Sans KR;
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.32px;
        margin-right: 8px;
      }
      .week {
        position: relative;
        margin-left: 8px;
        &:after {
          position: absolute;
          content: "";
          top: 50%;
          transform: translateY(-50%);
          left: -8px;
          width: 3px;
          height: 3px;
          background-color: #999;
          border-radius: 50%;
        }
      }
    }
  }

  .noti-wrap {
    padding: 30px 20px;
    display: flex;
    /* gap: 0 20px; */
    justify-content: space-between;
    align-items: baseline;
    white-space: nowrap;
    .date-box {
      font-family: Noto Sans KR;
      font-size: 16px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.32px;
      img {
        margin-right: 3px;
      }
      .week {
        margin-top: 6px;
        color: #666;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.28px;
        padding-left: 27px;
      }
    }
    .way-btn {
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.56);
      display: flex;
      gap: 4px;
      height: 28px;
      align-items: baseline;
      padding: 0 8px;
      color: #fff;
      font-family: Noto Sans KR;
      font-size: 12px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.24px;
      white-space: nowrap;
    }
  }
  .bold-line {
    border-top: 8px solid #00000008;
    margin: 2px 0 40px 0;
  }
  .situation-wrap {
    padding: 0 20px 40px 20px;
    .title-wrap {
      display: flex;
      justify-content: space-between;
      padding-top: 3px;

      .goals {
        border-radius: 10px;
        background: #000;
        padding: 0px 10px 0px 6px;
        height: 28px;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        line-height: 28px;
        position: relative;
        &:after {
          position: absolute;
          content: "";
          top: 27px;
          right: 20px;
          background-image: url(/images/Triangle.svg);
          width: 11px;
          height: 8px;
        }
      }
    }
  }
  .situation-list-box {
    border-radius: 10px;
    background: rgba(153, 153, 153, 0.4);
    margin-top: 16px;
    padding: 20px;
    .situation-list {
      display: flex;
      justify-content: center;
      gap: 8px;
      li {
        border-radius: 10px;
        border: 1px solid #fff;
        background: rgba(255, 255, 255, 0.4);
        width: 34px;
        height: 34px;
        color: #fff;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        line-height: 34px;
        &.active {
          background: rgba(0, 0, 0, 0.4);
        }
        img {
          width: 100%;
          border-radius: 10px;
          object-fit: cover;
        }
      }
    }
  }
  .certified-wrap {
    margin-top: 22px;
    .certified-list {
      display: flex;
      justify-content: center;
      li {
        max-width: 103px;
        width: 100%;
        border-left: 1px solid #e9e9e9;
        &:first-child {
          border-left: none;
        }
        p {
          display: block;
          color: #666;
          text-align: center;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: -0.24px;
          padding: 0 6px;
          white-space: nowrap;
        }
        h2 {
          text-align: center;
          font-size: 16px;
          font-weight: 700;
          letter-spacing: -0.32px;
          margin-top: 15px;
        }
      }
    }
  }
  .my {
    margin-top: 43px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      transition: all 0.3s;
      &:hover {
        opacity: 0.6;
      }
    }
    p {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.24px;
      img {
        margin-left: 4px;
        position: relative;
        top: -1px;
      }
    }
  }
  .myshot-box {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .myshot {
    width: 100px;
    width: calc((100% - 20px) / 3);
    height: 0;
    padding-bottom: 31.4%;
    border-radius: 10px;
    border: 1px solid #e9e9e9;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    cursor: pointer;
  }
  .certified-btn {
    padding: 10px 20px;
    border-top: 1px solid #e9e9e9;
    position: fixed;
    max-width: 500px;
    width: 100%;
    bottom: 51px;
    background-color: #fff;
    .bar {
      width: 130px;
      height: 5px;
      border-radius: 30px;
      background: #000;
      position: absolute;
      bottom: 8px;
      left: 50%;
      transform: translateX(-50%);
    }
    a {
      border-radius: 10px;
      background: #ffbc00;
      display: block;
      width: 100%;
      height: 50px;
      color: #000;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 48px;
      transition: all 0.3s;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .certified-btn-gray {
    padding: 10px 20px;
    border-top: 1px solid #e9e9e9;
    position: fixed;
    max-width: 500px;
    width: 100%;
    bottom: 51px;
    background-color: #fff;
    .bar {
      width: 130px;
      height: 5px;
      border-radius: 30px;
      background: #000;
      position: absolute;
      bottom: 8px;
      left: 50%;
      transform: translateX(-50%);
    }
    a {
      border-radius: 10px;
      border: 1px solid #e9e9e9;
      background: #f7f7f7;
      display: block;
      width: 100%;
      height: 50px;
      color: #999;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 48px;
    }
  }
  @media ${(props) => props.theme.mobile} {
  }
`;
