import React, { useState, useEffect } from "react";
import Layout from "../../componet/Layout";
import { WithChallengeWrapper } from "./styled";
import { GetChallenges, GetChallengesNum } from "../../service/challeng";
import { Link, useNavigate, useParams } from "react-router-dom";

const ChallengeList = () => {
  const handleTabClick = (tab) => {
    setSubSlug(tab);
  };

  const nav = useNavigate();
  const params = useParams();
  const [paged, setPaged] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(8);
  const [slug, setSlug] = useState("alone");
  const [subSlug, setSubSlug] = useState("");
  const [challenges, setChallenges] = useState([]);
  const [total, setTotal] = useState(0);
  const [maxPaged, setMaxPaged] = useState(0);

  useEffect(() => {
    GetChallenges({
      paged: 1,
      postsPerPage: postsPerPage,
      subSlug: params.slug,
    }).then((res) => {
      console.log(res);
      if (res.data.code === "200") {
        setChallenges(res.data.body.items);
        setTotal(res.data.body.total);
        setMaxPaged(res.data.body.maxPaged);
      } else {
        setChallenges(null);
        setTotal(0);
      }
    });
  }, [params]);

  useEffect(() => {
    if (paged > 1) {
      GetChallenges({
        paged: paged,
        postsPerPage: postsPerPage,
        slug: slug,
        subSlug: subSlug,
      })
        .then((res) => {
          console.log(res);
          if (res.data.code === "200") {
            setChallenges((p) => [...p, ...res.data.body.items]);
            setTotal(res.data.body.total);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [paged]);

  const [eachTotal, setEachTotal] = useState({});
  useEffect(() => {
    GetChallengesNum({}).then((res) => {
      console.log(res);
      if (res.data.code === "200") {
        setEachTotal(res.data.body.total);
        localStorage.setItem("esg_total", JSON.stringify(res.data.body.total));
      }
    });

    console.log(123, localStorage.getItem("esg_total"));
  }, []);

  const esgTotal = JSON.parse(localStorage.getItem("esg_total"));

  return (
    <>
      <Layout headerType={"home"}>
        <WithChallengeWrapper>
          <div className="wrapper">
            <ul className="tab-menu">
              <li
                className={!params?.slug ? "active" : ""}
                onClick={() => nav("/challenges")}
              >
                전체
                <span>
                  {esgTotal?.all
                    ? esgTotal?.all
                    : eachTotal?.all
                    ? eachTotal?.all
                    : 0}
                </span>
              </li>
              <li
                className={params?.slug === "eco" ? "active" : ""}
                onClick={() => nav("/challenges/eco")}
              >
                환경
                <span>
                  {esgTotal?.eco
                    ? esgTotal?.eco
                    : eachTotal?.eco
                    ? eachTotal?.eco
                    : 0}
                </span>
              </li>
              <li
                className={params?.slug === "culture" ? "active" : ""}
                onClick={() => nav("/challenges/culture")}
              >
                조직문화
                <span>
                  {esgTotal?.culture
                    ? esgTotal?.culture
                    : eachTotal?.culture
                    ? eachTotal?.culture
                    : 0}
                </span>
              </li>
              <li
                className={params?.slug === "health" ? "active" : ""}
                onClick={() => nav("/challenges/health")}
              >
                건강
                <span>
                  {esgTotal?.health
                    ? esgTotal?.health
                    : eachTotal?.health
                    ? eachTotal?.health
                    : 0}
                </span>
              </li>
            </ul>
            <div className="popular-box">
              <div className="content-environment">
                <>
                  <div className="challenge-content-wrap">
                    {challenges?.length ? (
                      challenges.map((item, key) => (
                        <Link
                          to={`/challenge/${item.ID}`}
                          className="challenge-content-box"
                        >
                          <div className="challenge-content">
                            <div
                              className="content-img"
                              style={{
                                backgroundImage: `url(${item.thumbnail})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                              }}
                            >
                              <div className="noti">주 {item.quota}회</div>
                            </div>
                            <div className="content-text-box">
                              <p className="categories">{item.cate}</p>
                              <p className="content-title">{item.postTitle}</p>
                              <div className="point-box">
                                <img src="/images/esg-point.svg" alt="" />
                                {item.point}
                              </div>
                              {/* <p className="date">{item.date}</p> */}
                            </div>
                          </div>
                        </Link>
                      ))
                    ) : challenges ? null : (
                      <div className="empty empty2">
                        <img src="/images/icon-x-square.svg" alt="" />
                        <p className="empty-text">내역이 없습니다.</p>
                      </div>
                    )}
                  </div>
                </>
              </div>
              {paged < maxPaged ? (
                <div className="show-box">
                  <div
                    onClick={() => setPaged((p) => p + 1)}
                    className="show-more"
                  >
                    더보기
                    <img src="/images/esg-plus-icon.svg" alt="" />
                  </div>
                </div>
              ) : (
                <div style={{ height: "140px" }} />
              )}
            </div>
          </div>
        </WithChallengeWrapper>
      </Layout>
    </>
  );
};

export default ChallengeList;
