import styled from "styled-components";

export const CompensationWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  .wrapper {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
    padding-bottom: 100px;
  }
  a {
    text-decoration: none;
    color: #000;
  }
  .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 160%;
    letter-spacing: -0.36px;
  }

  .point-wrap {
    padding: 80px 20px 0 20px;
  }
  .point-box {
    border-radius: 10px;
    background: #f7f7f7;
    text-align: center;
    padding: 30px 0;
    margin-top: 10px;
    img {
      width: 36px;
    }
    .total {
      color: #000;
      margin-top: 9px;
      font-size: 22px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.44px;
      font-family: Noto Sans KR;
    }
    .accrue {
      font-family: Noto Sans KR;
      color: #999;
      font-size: 13px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.26px;
      margin-top: 6px;
    }
  }
  .compensation-btn-box {
    margin-top: 14px;

    a {
      border-radius: 10px;
      background: #ffbc00;
      display: block;
      width: 100%;
      height: 46px;
      text-align: center;
      color: #000;
      font-size: 14px;
      font-weight: 700;
      line-height: 46px;
      letter-spacing: -0.28px;

      img {
        margin-right: 6px;
        position: relative;
        top: -1px;
      }
    }
  }
  .deactivation-btn-box {
    margin-top: 16px;

    a {
      color: #999999;
      border-radius: 10px;
      border: 1px solid #e9e9e9;
      background: #f7f7f7;
      display: block;
      width: 100%;
      height: 46px;
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      line-height: 46px;
      letter-spacing: -0.28px;
      cursor: default !important;

      img {
        margin-right: 6px;
        position: relative;
        top: -1px;
      }
    }
  }
  .compensation-noti {
    margin-top: 16px;
    p {
      font-size: 11px;
      font-weight: 400;
      line-height: 160%;
    }
  }
  .bold-line {
    border-top: 8px solid #00000008;
    margin: 40px 0;
  }
  .breakdown {
    padding: 0 20px;
    h2 {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 700;
      line-height: 160%;
      letter-spacing: -0.36px;
      margin-bottom: 16px;
      select {
        color: #000;
        text-align: right;
        font-family: Noto Sans KR;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.28px;
        border: none;
        background-color: #fff;
      }
    }
    .breakdown-box {
      margin-top: 10px;
      border-radius: 10px;
      border: 1px solid #e9e9e9;
      padding: 17px 20px 18px 20px;
      .top-box {
        display: flex;
        justify-content: space-between;
        .date {
          color: #999;
          font-size: 12px;
          font-weight: 400;
          line-height: 160%;
          letter-spacing: -0.24px;
        }
        .use {
          color: #ffbc00;
          font-size: 14px;
          font-weight: 700;
          line-height: 160%;
          letter-spacing: -0.28px;
        }
      }
      .under-box {
        display: flex;
        justify-content: space-between;
        margin-top: 2px;
        .completed {
          font-size: 13px;
          font-weight: 700;
          letter-spacing: -0.26px;
        }
        .total-point {
          color: #999;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: -0.24px;
        }
      }
    }
  }

  @media ${(props) => props.theme.mobile} {
  }
`;
