import styled from "styled-components";

export const CameraWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  .wrapper {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
  }
  a {
    text-decoration: none;
    color: #000;
  }
  .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 160%;
    letter-spacing: -0.4px;
    margin-top: 18px;
  }

  .photo-wrap {
    padding: 80px 20px 120px 20px;

    .photo-box {
      display: block;
      border-radius: 10px;
      background: #f7f7f7;
      height: 320px;
      position: relative;
      margin-top: 13px;
      cursor: pointer;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      input {
        display: none;
      }
      &#disable {
        pointer-events: none;
      }
      .photo-text-box {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        img {
          margin-top: -10px;
        }
        p {
          color: #666;
          font-size: 12px;
          margin-top: 8px;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
    .textarea-box {
      margin-top: 10px;
      textarea {
        border-radius: 10px;
        border: 1px solid #e9e9e9;
        background: #fff;
        height: 138px;
        width: 100%;
        resize: none;
        padding: 16px 18px 17px 18px;
        box-sizing: border-box;

        font-family: Noto Sans KR;
        font-size: 13px;
        font-weight: 400;
        line-height: 160%;
        letter-spacing: -0.26px;
        &::placeholder {
          color: #999;
        }
      }
    }
    .btn-box {
      display: flex;
      gap: 8px;
      margin-top: 80px;
      button {
        width: 50%;
        height: 50px;
        color: #000;
        text-align: center;
        font-family: Noto Sans KR;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 10px;
        background: #ffbc00;
        border: none;
        transition: all 0.3s;
        &:hover {
          opacity: 0.8;
        }
      }
      .back-btn {
        border: 1px solid #e9e9e9;
        background: #f7f7f7;
      }
    }
  }
  .btn-box {
    border-top: 1px solid #e9e9e9;
    margin-top: 70px;
    padding-bottom: 10px;
    position: fixed;
    bottom: 0px;
    max-width: 500px;
    width: 100%;
    z-index: 100;
    background-color: #fff;
    .btn-padding {
      padding: 0 20px;
      display: flex;
      gap: 8px;
      margin-top: 10px;
    }
    button {
      width: 50%;
      height: 50px;
      color: #000;
      text-align: center;
      font-family: Noto Sans KR;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-radius: 10px;
      background: #ffbc00;
      border: none;
      transition: all 0.3s;
      &:hover {
        opacity: 0.8;
      }
    }
    .back-btn {
      border: 1px solid #e9e9e9;
      background: #f7f7f7;
    }
    .bar {
      width: 130px;
      height: 5px;
      border-radius: 30px;
      background: #000;
      position: absolute;
      bottom: 8px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @media ${(props) => props.theme.mobile} {
  }
`;
