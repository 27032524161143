import styled from "styled-components";

export const ParticipatingWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  .wrapper {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
    padding-bottom: 100px;
  }

  .select {
    width: 100%;
    height: 46px;
    border-radius: 10px;
    border: 1px solid #e9e9e9;
    background: #fff;
    margin-top: 26px;
    padding: 0 14px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.28px;
    cursor: pointer;
  }

  a {
    text-decoration: none;
    color: #000;
  }
  .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 160%;
    letter-spacing: -0.36px;
  }
  .tab-menu {
    display: flex;
    align-items: center;
    gap: 40px;
    margin-bottom: 10px;
    position: fixed;
    z-index: 123456789;
    background-color: #fff;
    max-width: 500px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 20px;
    margin-top: 98px;

    li {
      color: #9b9b9b;
      font-family: Noto Sans KR;
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.7px;
      border-bottom: 2px solid transparent;
      padding-bottom: 10px;
      cursor: pointer;
      display: flex;

      gap: 3px;
      align-items: center;
      &.active {
        color: #000;
        border-bottom: 2px solid #000;
      }
      &:hover {
        opacity: 0.8;
      }
      &.active span {
        border-radius: 30px;
        background: #000;
        width: 24px;
        height: 20px;
        display: inline-block;
        color: #fff;
        font-family: Noto Sans KR;
        font-size: 11px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.55px;
        text-align: center;
      }
    }
  }
  .popular-box {
    padding: 12px 20px 0 20px;
    padding-top: 116px;

    .popular-title {
      font-size: 20px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.4px;
    }
  }
  .content-wrap-wrapper {
    /* padding-top: 104px; */
  }
  .challenge-content-wrap {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 0 10px;
    margin-top: 23px;
    .challenge-content-box {
      width: calc((100% - 10px) / 2);
    }
    .challenge-content {
      margin-bottom: 22px;
      position: relative;

      &:hover .dim {
        opacity: 1;
      }
      .dim {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.7);
        opacity: 0;
        transition: all 0.3s;
        p {
          color: #fff;
          font-size: 12px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.24px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .content-img {
        width: 100%;
        height: 0;
        padding-bottom: 78%;
        background-image: url(/images/popular-challenge01.png);
        border-radius: 10px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: relative;
        .noti {
          border-radius: 4px;
          background: rgba(0, 0, 0, 0.56);
          display: inline-block;
          color: #fff;
          font-size: 11px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.22px;
          padding: 4px 6px 5px 6px;
          position: absolute;
          top: 9px;
          right: 8px;
        }
      }
      .content-text-box {
        margin-top: 13px;
        .categories {
          color: #666;
          font-size: 12px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.24px;
        }
        .content-title {
          font-size: 14px;
          font-weight: 700;
          line-height: 160%;
          letter-spacing: -0.28px;
          margin-top: 3px;
        }
        .point-box {
          /* margin-top: 7px; */
          display: flex;
          align-items: center;
          gap: 4px;
          font-size: 12px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.24px;
          margin-top: 6px;
          padding-bottom: 2px;
        }
        .date {
          color: #666;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: -0.24px;
          margin-top: 10px;
        }
      }
    }
  }
  .show-box {
    padding-bottom: 10px;
    .show-more {
      border-radius: 50px;
      border: 1px solid #e9e9e9;
      background: #fff;
      width: 112px;
      height: 42px;
      margin: 20px auto 40px auto;
      color: #000;
      font-size: 13px;
      font-weight: 500;
      line-height: 40px;
      letter-spacing: -0.26px;
      display: flex;
      align-items: center;
      gap: 6px;
      justify-content: center;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        opacity: 0.6;
      }
    }
  }
  .bar {
    width: 56px;
    height: 4px;
    background: #e9ecef;
    border-radius: 10px;
    margin: 0 auto;
  }
  .logo-box {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 14px;
    h2 {
      font-family: Noto Sans KR;
      font-size: 18px;
      font-weight: 700;
      line-height: 160%;
      letter-spacing: -0.54px;
      display: flex;
      gap: 6px;
      margin-left: -10px;
      text-align: center;
      justify-content: center;
      width: 100%;
      span {
        color: rgba(0, 0, 0, 0.6);
      }
    }
    img {
      cursor: pointer;
    }
  }
  .esg-header {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    background-color: #fff;
    max-width: 500px;
    width: 100%;
    box-sizing: border-box;
    height: 126px;
    padding: 12px 32px 12px 20px;
    border-bottom: ${(props) =>
      props.scrolled ? "1px solid #e9e9e9" : "1px solid #e9e9e9"};
    box-shadow: ${(props) =>
      props.scrolled ? "0px 3px 12px 0px rgba(0, 0, 0, 0.03)" : "none"};
  }
  @media ${(props) => props.theme.mobile} {
  }
`;
