import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./container/Main";
import Mypage from "./container/Mypage";
import Gauge from "./container/MypageGuage";
import AuthList from "./container/MypageAuthList";
import AuthSingle from "./container/EsgAuthSingle";
import MypageAuthList from "./container/MypageAuthList";
import EsgAuthList from "./container/EsgAuthList";
import MypageAuth from "./container/MypageAuth";
import Point from "./container/MypagePoint";
import Alone from "./container/Alone";
import Colleague from "./container/Colleague";
import Family from "./container/Family";

import EsgSingle from "./container/EsgSingle";
import Camera from "./container/Camera";
import Other from "./container/other";

import Rewards from "./container/MypagePoint";
import EsgCompleted from "./container/Esgcompleted";
import OtherFeed from "./container/otherFeed";
import { RecoilRoot, useRecoilState } from "recoil";
import ChallengeList from "./container/ChallengeList";
import ScrollToTop from "./componet/ScrollTop";
import Loading from "./componet/Loading";
import { loadingAtom } from "./store/loading";

const App = () => {
  const [loading, setLoading] = useRecoilState(loadingAtom);

  return (
    <div className="App">
      {/* <RecoilRoot> */}
      <BrowserRouter>
        <ScrollToTop />
        {loading ? <Loading /> : null}

        <Routes>
          <Route path="/" element={<Main />}></Route>
          <Route exact={true} path="/mypage" element={<Mypage />} />
          <Route exact={true} path="/gauge" element={<Gauge />} />
          <Route exact={true} path="/esgauthlist" element={<EsgAuthList />} />
          <Route exact={true} path="/challenge/:ID" element={<AuthSingle />} />
          <Route
            exact={true}
            path="/mypageauthlist"
            element={<MypageAuthList />}
          />
          <Route
            exact={true}
            path="/mypage/authlist"
            element={<MypageAuth />}
          />
          <Route exact={true} path="/rewards" element={<Rewards />} />
          <Route exact={true} path="/alone" element={<Alone />} />
          <Route exact={true} path="/colleague" element={<Colleague />} />
          <Route exact={true} path="/family" element={<Family />} />
          <Route
            exact={true}
            path="/challenges/:slug"
            element={<ChallengeList />}
          />
          <Route exact={true} path="/challenges/" element={<ChallengeList />} />
          <Route exact={true} path="/mypage/esg/:ID" element={<EsgSingle />} />
          <Route exact={true} path="/auth/:ID" element={<Camera />} />
          <Route exact={true} path="/other/:ID" element={<Other />} />
          <Route
            exact={true}
            path="/mypage/authlist/:ID"
            element={<EsgCompleted />}
          />
          <Route exact={true} path="/authlist/:ID" element={<OtherFeed />} />
        </Routes>
      </BrowserRouter>
      {/* </RecoilRoot> */}
    </div>
  );
};
export default App;
