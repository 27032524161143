import React, { useState, useEffect } from "react";
import Layout from "../../componet/Layout";
import { WithChallengeWrapper } from "./styled";
import { GetChallenges } from "../../service/challeng";
import { Link } from "react-router-dom";
import Footer from "../../componet/Footer";
const Family = () => {
  const [selectedTab, setSelectedTab] = useState("전체");
  const handleTabClick = (tab) => {
    setSubSlug(tab);
  };

  const [paged, setPaged] = useState(1);

  const [postsPerPage, setPostsPerPage] = useState(8);
  const [slug, setSlug] = useState("family");
  const [subSlug, setSubSlug] = useState("");
  const [challenges, setChallenges] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    GetChallenges({
      paged: 1,
      postsPerPage: postsPerPage,
      slug: slug,
      subSlug: subSlug,
    })
      .then((res) => {
        console.log(res);
        if (res.data.code === "200") {
          setChallenges(res.data.body.items);
          setTotal(res.data.body.total);
        } else {
          setChallenges(null);
          setTotal(0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [subSlug]);

  useEffect(() => {
    if (paged > 1) {
      GetChallenges({
        paged: paged,
        postsPerPage: postsPerPage,
        slug: slug,
        subSlug: subSlug,
      })
        .then((res) => {
          console.log(res);
          setChallenges((p) => [...p, ...res.data.body.items]);
          setTotal(res.data.body.total);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [paged]);

  return (
    <>
      <Layout headerTitle={"가족과 함께하는 챌린지"} footerType={"none"}>
        <WithChallengeWrapper>
          <div className="wrapper">
            <div className="main-wrapper">
              <div className="main-banner">
                <h2 className="main-title">가족과 함께하는 챌린지</h2>
              </div>
            </div>
            <div className="popular-box">
              <ul className="tab-menu">
                <li
                  className={subSlug === "" ? "active" : ""}
                  onClick={() => handleTabClick("")}
                >
                  전체
                </li>
                <li
                  className={subSlug === "eco" ? "active" : ""}
                  onClick={() => handleTabClick("eco")}
                >
                  환경
                </li>
                <li
                  className={subSlug === "culture" ? "active" : ""}
                  onClick={() => handleTabClick("culture")}
                >
                  조직문화
                </li>
                <li
                  className={subSlug === "health" ? "active" : ""}
                  onClick={() => handleTabClick("health")}
                >
                  건강
                </li>
              </ul>
              <div className="content-environment">
                <div className="challenge-content-wrap">
                  {challenges?.length ? (
                    challenges.map((item, key) => (
                      <Link
                        to={`/challenge/${item.ID}`}
                        className="challenge-content-box"
                      >
                        <div className="challenge-content">
                          <div
                            className="content-img"
                            style={{
                              backgroundImage: `url(${item.thumbnail})`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          >
                            <div className="noti">주 {item.quota}회</div>
                          </div>
                          <div className="content-text-box">
                            <p className="categories">{item.cate}</p>
                            <p className="content-title">{item.postTitle}</p>
                            <div className="point-box">
                              <img src="/images/esg-point.svg" alt="" />
                              {item.point}
                            </div>
                            <p className="date">{item.date}</p>
                          </div>
                        </div>
                      </Link>
                    ))
                  ) : challenges ? null : (
                    <div className="empty empty2">
                      <img src="/images/icon-x-square.svg" alt="" />
                      <p className="empty-text">내역이 없습니다.</p>
                    </div>
                  )}
                </div>
              </div>

              {total > 8 ? (
                <div className="show-box">
                  <div
                    onClick={() => setPaged((p) => p + 1)}
                    className="show-more"
                  >
                    더보기
                    <img src="/images/esg-plus-icon.svg" alt="" />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </WithChallengeWrapper>
        <Footer />
      </Layout>
    </>
  );
};

export default Family;
