import React, { useEffect, useState } from "react";
import Layout from "../../componet/Layout";
import { OthereWrapper } from "./styled";

import { Link, useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";
import { GetAuthByChallenge, GetChallenge } from "../../service/challeng";
import { useSetRecoilState } from "recoil";
import { loadingAtom } from "../../store/loading";

const Other = () => {
  const [hasContent] = useState(true);

  const nav = useNavigate();
  const params = useParams();
  console.log(params);
  const [item, setItem] = useState();
  const [authes, setAuthes] = useState([]);
  const [paged, setPaged] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(12);
  const [maxPaged, setMaxPaged] = useState(1);
  const setLoading = useSetRecoilState(loadingAtom);

  useEffect(() => {
    setLoading(true);
    GetChallenge({
      challengeId: params?.ID,
      isAuth: 1,
    })
      .then((res) => {
        console.log("res", res);
        if (res.data.code === "200") {
          setItem(res.data.body);
          console.log(res.data.body);
        } else {
          setItem(null);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    GetAuthByChallenge({
      challengeId: params?.ID,
      isSimple: 1,
      paged: paged,
      postsPerPage: postsPerPage,
    })
      .then((res) => {
        if (res.data.code === "200") {
          setAuthes(res.data.body.items);
          setMaxPaged(res.data.body.maxPaged);
          console.log(res.data.body);
        } else {
          setAuthes(null);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [params?.ID]);

  useEffect(() => {
    if (paged > 1) {
      GetAuthByChallenge({
        challengeId: params?.ID,
        isSimple: 1,
      })
        .then((res) => {
          if (res.data.code === "200") {
            setAuthes((p) => [...p, ...res.data.body.items]);
            console.log(res.data.body);
          } else {
            setAuthes(null);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [paged]);

  const DoMore = () => {
    setPaged((p) => p + 1);
  };

  return (
    <div>
      <Layout headerTitle={"모든 참여자들의 인증"} footerType={"none"}>
        <OthereWrapper>
          <div className="wrapper">
            {/* <div className="main-banner">
              <div className="under-menu">
                <div className="Participation">
                  <p className="categories">{item?.cate}</p>
                  <p className="main-title">{item?.postTitle}</p>
                  <div className="number">
                    <img src="/images/esg-point.svg" alt="" />
                    <p>{item?.point}</p>
                    <p className="week">주 {item?.quota}회</p>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="noti-wrap">
              <div className="date-box">
                <p>
                  <img src="/images/icon-calendar.svg" alt="" />
                  {item?.date}
                </p>
                <p className="week">주 {item?.quota}회, 1주동안</p>
              </div>
              <a href="#" className="way-btn">
                <span>인증방법 및 주의사항</span>
                <img src="/images/noti-arrow.svg" alt="" />
              </a>
            </div>
            {/* <div className="bold-line"></div> */}
            <div className="situation-wrap">
              <div className="title-wrap">
                {/* <p className="title">모든 참여자들의 인증</p> */}
              </div>
              <div className="certified-wrap">
                <ul className="certified-list">
                  <li>
                    <p>총 참여자 수</p>
                    <h2>{item?.participantNum}명</h2>
                  </li>
                  <li>
                    <p>평균 달성률</p>
                    <h2>{item?.rate}%</h2>
                  </li>
                </ul>
              </div>

              <>
                <div className="myshot-box">
                  {authes?.length ? (
                    authes.map((i, key) => (
                      <div
                        className="myshot"
                        key={key}
                        style={{
                          backgroundImage: `url(${i?.thumbnail})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                        onClick={() => nav(`/authlist/${params?.ID}#${i.ID}`)}
                      ></div>
                    ))
                  ) : authes ? (
                    ""
                  ) : (
                    <div className="empty">
                      <img src="/images/icon-x-square.svg" alt="" />
                      <p className="empty-text">아직 등록된 인증이 없습니다.</p>
                    </div>
                  )}
                </div>
                {paged >= maxPaged ? null : (
                  <div className="more-btn-box">
                    <a onClick={DoMore}>
                      더보기
                      <img src="/images/more-plus.svg" alt="" />
                    </a>
                  </div>
                )}
              </>
            </div>
            {item?.isTodayAuth && item?.authComplete ? (
              <div className="certified-btn-gray">
                <a>인증 완료</a>
              </div>
            ) : (
              <div className="certified-btn">
                <a href={`/auth/${item?.ID}`}>인증하기</a>
                {/* <div className="bar"></div> */}
              </div>
            )}
          </div>
        </OthereWrapper>
      </Layout>
    </div>
  );
};

export default Other;
