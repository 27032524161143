import React, { useEffect, useState } from "react";
import Layout from "../../componet/Layout";
import { CertifiedWrapper } from "./styled";
import {
  DeleteAuth,
  GetAuthIsMine,
  GetAuthSingle,
  GetAuthesNum,
  InsertLike,
} from "../../service/challeng";
import { useParams } from "react-router-dom";
import { insert } from "../../service/commnet";
import { userData } from "../../store/users";
import { useRecoilState, useSetRecoilState } from "recoil";
import Footer from "../../componet/Footer";
import Alert from "../../componet/Alert";
import { loadingAtom } from "../../store/loading";

const MypageAuth = () => {
  //
  const params = useParams();
  const [subSlug, setSubSlug] = useState("");
  const [eachTotal, setEachTotal] = useState({});
  const [user, setUser] = useRecoilState(userData);
  const setRecoilLoading = useSetRecoilState(loadingAtom);

  const [paged, setPaged] = useState(1);

  const [commentShow, setCommentShow] = useState(false);

  const [profileShow, setProfileShow] = useState(false);

  const ShowProfile = () => {
    setProfileShow((p) => !p);
  };

  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [call, setCall] = useState(false);
  const [maxPaged, setMaxPaged] = useState(1);
  const [once, setOnce] = useState(false);

  useEffect(() => {
    setRecoilLoading(true);
    GetAuthIsMine({
      subSlug,
      paged: 1,
      postsPerPage: 8,
    })
      .then((res) => {
        console.log(res);
        if (res.data.code === "200") {
          setItems(res.data.body.items);
          setTotal(res.data.body.total);
          setMaxPaged(res.data.body.maxPaged);
          setOnce((p) => !p);
        } else {
          setItems(null);
          setTotal(0);
        }
        setRecoilLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setRecoilLoading(false);
      });
  }, [call, subSlug]);

  useEffect(() => {
    if (paged > 1) {
      GetAuthIsMine({
        subSlug,
        paged: paged,
        postsPerPage: 8,
      })
        .then((res) => {
          console.log(res);
          if (res.data.code === "200") {
            setItems((p) => [...p, ...res.data.body.items]);
            setTotal(res.data.body.total);
          } else {
            setItems(null);
            setTotal(0);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [paged]);

  useEffect(() => {
    GetAuthesNum({}).then((res) => {
      console.log(res);
      if (res.data.code === "200") {
        setEachTotal(res.data.body.total);
      }
    });
  }, []);

  function getPageOffsetsByIds(className) {
    const elements = document.getElementsByClassName(className);
    const pageOffsets = [];

    for (const element of elements) {
      const rect = element.getBoundingClientRect();
      if (rect?.y) {
        const offsetY = rect.top + window.pageYOffset;
        window.scrollTo({
          top: offsetY,
          behavior: "smooth", // You can use 'auto' or 'smooth'
        });
        return null;
      }
    }
  }

  useEffect(() => {
    if (window.location.hash && items) {
      setTimeout(() => {
        getPageOffsetsByIds(window.location.hash.replace("#", ""));
      }, [50]);
    }
  }, [once, window.location.hash]);

  const [expandedItem, setExpandedItem] = useState(null);
  const handleAccordionClick = (index) => {
    setExpandedItem(index === expandedItem ? null : index);
  };

  const handleTabClick = (tab) => {
    setItems([]);
    setSubSlug(tab);
  };
  const [comment, setComment] = useState("");

  const commentSubmit = (authId) => {
    if (!comment) {
      return;
    }
    setAuthId(authId);
    toggle3();
  };

  const submit = () => {
    setRecoilLoading(true);
    insert({
      authId: authId,
      content: comment,
    })
      .then((res) => {
        // setCall((p) => !p);
        getSingle(authId);
        toggle4();
        setComment("");
        console.log(res);
        setRecoilLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setRecoilLoading(false);
      });
  };

  const getSingle = (authId) => {
    GetAuthSingle({
      authId: authId,
    })
      .then((res) => {
        console.log(res);
        if (res.data.code === "200") {
          setItems(res.data.body.items);
          setTotal(res.data.body.total);

          const targetPropertyValue = authId;
          const newObject = res.data.body;

          const index = items.findIndex(
            (obj) => obj.ID === targetPropertyValue
          );
          if (index !== -1) {
            const updatedArray = [...items];
            updatedArray[index] = newObject;
            setItems(updatedArray);
          }
        } else {
          setItems(null);
          setTotal(0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DEFAULT_USER_IMAGE = "/images/user-null.png";

  const DoLike = (authId) => {
    InsertLike({
      authId: authId,
    })
      .then((res) => {
        getSingle(authId);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const GoBack = () => {
    window.history.back();
  };

  const [image, setImage] = useState("");
  const [show, setShow] = useState(false);
  const showImage = (thumbnail) => {
    setImage(thumbnail);
    setShow(true);
  };

  const [loading, setLoading] = useState(false);

  const handleScroll = () => {
    if (!loading) {
      console.log(1);
      let offsetHeight = document.documentElement.offsetHeight;
      let scrollTop = document.documentElement.scrollTop;
      let innerHeight = window.innerHeight;
      console.log(offsetHeight - (innerHeight + scrollTop));
      console.log(paged < maxPaged);
      if (offsetHeight - (innerHeight + scrollTop) < 300 && paged < maxPaged) {
        setLoading(true);
        setPaged((p) => p + 1);
        console.log(2);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const [authId, setAuthId] = useState("");
  const DoDelete = (authId) => {
    setAuthId(authId);
    toggle();
  };

  const delete_ = () => {
    DeleteAuth({
      authId: authId,
    })
      .then((res) => {
        setCall((p) => !p);
        toggle2();
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [active, setActive] = useState(false);
  const toggle = () => {
    setActive((p) => !p);
  };

  const [active2, setActive2] = useState(false);
  const toggle2 = () => {
    setActive2((p) => !p);
  };

  const [active3, setActive3] = useState(false);
  const toggle3 = () => {
    setActive3((p) => !p);
  };

  const [active4, setActive4] = useState(false);
  const toggle4 = () => {
    setActive4((p) => !p);
  };

  return (
    <>
      <Layout
        headerTitle={"나의 챌린지 인증"}
        footerType={"none"}
        headerType={"tab"}
      >
        <CertifiedWrapper>
          <div className="wrapper">
            <ul className="tab-menu">
              <li
                className={subSlug === "" ? "active" : ""}
                onClick={() => handleTabClick("")}
              >
                전체<span>{eachTotal?.all ? eachTotal?.all : 0}</span>
              </li>
              <li
                className={subSlug === "eco" ? "active" : ""}
                onClick={() => handleTabClick("eco")}
              >
                환경<span>{eachTotal?.eco ? eachTotal?.eco : 0}</span>
              </li>
              <li
                className={subSlug === "culture" ? "active" : ""}
                onClick={() => handleTabClick("culture")}
              >
                조직문화
                <span>{eachTotal?.culture ? eachTotal?.culture : 0}</span>
              </li>
              <li
                className={subSlug === "health" ? "active" : ""}
                onClick={() => handleTabClick("health")}
              >
                건강<span>{eachTotal?.health ? eachTotal?.health : 0}</span>
              </li>
            </ul>

            <div className="popular-box">
              {items?.length ? (
                items.map((item, index) => (
                  <div className="certified-wrap" key={index}>
                    <div id="tmp" className={item.ID}></div>
                    <div className="my-info">
                      <div className="left-info">
                        <div
                          className="user-img"
                          style={{
                            backgroundImage: `url(${
                              item?.authorAvatar || DEFAULT_USER_IMAGE
                            })`,
                          }}
                        ></div>
                        <div>
                          <p>{item.authorName}</p>
                          <span>{item.date}</span>
                        </div>
                      </div>
                      {item?.isTodayAuth ? (
                        <div onClick={() => DoDelete(item?.ID)}>
                          <img
                            src="/images/recycle.svg"
                            alt=""
                            className="icon-recycle"
                          />
                        </div>
                      ) : null}
                    </div>
                    <div
                      className="photo"
                      style={{
                        backgroundImage: `url(${item.thumbnail})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      <div className="like-box">
                        <p onClick={() => DoLike(item?.ID)}>
                          {item?.isLike ? (
                            <img
                              src="/images/like-active.svg"
                              alt=""
                              className="like-btn"
                            />
                          ) : (
                            <img
                              src="/images/like.svg"
                              alt=""
                              className="like-btn"
                            />
                          )}
                          좋아요 {item?.likeCount ? item?.likeCount : ""}
                        </p>
                        <button onClick={() => handleAccordionClick(index)}>
                          <img src="/images/chat.svg" alt="" />
                          댓글 <span>{item?.commentCount}</span>
                        </button>
                      </div>
                      <img
                        onClick={() => showImage(item.thumbnail)}
                        className="r"
                        src="/images/freat.png"
                      />
                    </div>
                    <div className="photo-content">
                      <p className="photo-title">{item.challengeTitle}</p>
                      <p
                        className="photo-text"
                        dangerouslySetInnerHTML={{ __html: item?.content }}
                      ></p>
                    </div>
                    {index == expandedItem ? (
                      <div className="comment-box">
                        {item?.comments?.length ? (
                          item?.comments?.map((comment, i) => (
                            <div key={i}>
                              <div className="user-info">
                                <div
                                  onClick={ShowProfile}
                                  className="user"
                                  style={{
                                    backgroundImage: `url(${comment?.author?.thumbnail})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                  }}
                                ></div>
                                <p className="name">{comment?.author?.name}</p>
                              </div>
                              <p className="comment">{comment?.content}</p>
                              <p className="date">{comment?.date}</p>
                            </div>
                          ))
                        ) : (
                          <div className="empty empty2">
                            <img src="/images/icon-x-square.svg" alt="" />
                            <p className="empty-text">내역이 없습니다.</p>
                          </div>
                        )}
                        <div className="chat-box">
                          <div
                            className="my-profile"
                            style={{
                              backgroundImage: `url(${
                                user?.avatar || DEFAULT_USER_IMAGE
                              })`,
                            }}
                          ></div>
                          <input
                            type="text"
                            placeholder="댓글을 입력해 주세요"
                            onChange={(e) => setComment(e.target.value)}
                            value={comment}
                          />
                          <img
                            src="/images/prime_send.svg"
                            alt=""
                            className="send"
                            onClick={() => commentSubmit(item?.ID)}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))
              ) : items ? (
                ""
              ) : (
                <div className="empty empty2">
                  <img src="/images/icon-x-square.svg" alt="" />
                  <p className="empty-text">인증내역이 없습니다.</p>
                </div>
              )}
            </div>

            <div
              className={profileShow ? "other-profile active" : "other-profile"}
            >
              <div className="bar" onClick={ShowProfile}></div>
              <div className="my-info">
                <div className="user-img"></div>
                <div>
                  <p>홍길동</p>
                  <span>
                    <img src="/images/esg-point.svg" alt="" />
                    13,280
                  </span>
                </div>
              </div>
              <div className="situation-wrap">
                <a href="/">
                  <p>참여중</p>
                  <h5>2</h5>
                </a>
                <a href="/">
                  <p>인증</p>
                  <h5>34</h5>
                </a>
              </div>
              <div className="profile-btn">
                <a href="/">
                  <img src="/images/person.svg" alt="" />
                  프로필 보기
                </a>
              </div>
            </div>
          </div>
          {show ? (
            <div className="modal" onClick={() => setShow(false)}>
              <div className="closebtn">
                <img src={"/images/closebtn.png"} />
              </div>
              <div style={{ backgroundImage: `url(${image})` }} />
            </div>
          ) : null}

          <Alert
            active={active}
            toggle={toggle}
            event={delete_}
            title="삭제하시겠습니까?"
          />
          <Alert
            active={active2}
            toggle={toggle2}
            title="삭제가 완료되었습니다."
            oneEvent={true}
          />
          <Alert
            active={active3}
            toggle={toggle3}
            title="댓글을 작성하시겠습니까?"
            event={submit}
          />
          <Alert
            active={active4}
            toggle={toggle4}
            title="작성이 완료되었습니다."
            oneEvent={true}
          />
        </CertifiedWrapper>
        <Footer />
      </Layout>
    </>
  );
};

export default MypageAuth;
