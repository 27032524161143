import React, { useEffect, useState } from "react";
import { Wrapper } from "./styled";
import Layout from "../../componet/Layout";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import "swiper/css";
import Alert from "../../componet/Alert";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import {
  GetChallengesNum,
  GetChallenges,
  GetAuthAll,
} from "../../service/challeng";
import { Link, useNavigate } from "react-router-dom";
const Main = (props) => {
  const [selectedTab, setSelectedTab] = useState("");
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };
  const DEFAULT_USER_IMAGE = "/images/user-null.png";
  SwiperCore.use([Autoplay]);

  const nav = useNavigate();
  const [paged, setPaged] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(12);
  const [challenges, setChallenges] = useState([]);
  const [total, setTotal] = useState(0);
  const [maxPaged, setMaxPaged] = useState(0);

  const [auth, setAuth] = useState([]);

  useEffect(() => {
    GetAuthAll({
      paged: 1,
      postsPerPage: 5,
    })
      .then((res) => {
        console.log("authes", res);
        if (res.data.code == "200") {
          // setAuth(null);
          setAuth(res.data.body.items);
        } else {
          setAuth(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (paged > 1) {
      GetChallenges({
        paged: paged,
        postsPerPage: postsPerPage,
        subSlug: selectedTab,
      }).then((res) => {
        console.log(res);
        if (res.data.code === "200") {
          setChallenges((p) => [...p, ...res.data.body.items]);
          setTotal(res.data.body.total);
          setMaxPaged(res.data.body.maxPaged);
        } else {
          setChallenges(null);
          setTotal(0);
        }
      });
    }
  }, [paged]);

  useEffect(() => {
    GetChallenges({
      paged: 1,
      postsPerPage: postsPerPage,
      subSlug: selectedTab,
    }).then((res) => {
      console.log(res);
      if (res.data.code === "200") {
        setChallenges(res.data.body.items);
        setTotal(res.data.body.total);
        setMaxPaged(res.data.body.maxPaged);
      } else {
        setChallenges(null);
        setTotal(0);
      }
    });
  }, [selectedTab]);

  const [subSlug, setSubSlug] = useState("");
  const [eachTotal, setEachTotal] = useState({});

  useEffect(() => {
    GetChallengesNum({}).then((res) => {
      console.log(res);
      if (res.data.code === "200") {
        setEachTotal(res.data.body.total);
        localStorage.setItem("esg_total", JSON.stringify(res.data.body.total));
      }
    });

    console.log(123, localStorage.getItem("esg_total"));
  }, []);

  const esgTotal = JSON.parse(localStorage.getItem("esg_total"));

  const [active, setActive] = useState(false);
  const toggle = () => {
    setActive((p) => !p);
  };
  return (
    <>
      <Layout headerType={"home"}>
        <Wrapper>
          <div className="wrapper">
            {/* <button onClick={toggle}>전송</button> */}
            {/* <ul className="tab-menu">
            <li className={subSlug === "" ? "active" : ""}>
              전체
              <span>
                {esgTotal?.all
                  ? esgTotal?.all
                  : eachTotal?.all
                  ? eachTotal?.all
                  : 0}
              </span>
            </li>
            <li
              className={subSlug === "eco" ? "active" : ""}
              onClick={() => nav("/challenges/eco")}
            >
              환경
              <span>
                {esgTotal?.eco
                  ? esgTotal?.eco
                  : eachTotal?.eco
                  ? eachTotal?.eco
                  : 0}
              </span>
            </li>
            <li
              className={subSlug === "culture" ? "active" : ""}
              onClick={() => nav("/challenges/culture")}
            >
              조직문화
              <span>
                {esgTotal?.culture
                  ? esgTotal?.culture
                  : eachTotal?.culture
                  ? eachTotal?.culture
                  : 0}
              </span>
            </li>
            <li
              className={subSlug === "health" ? "active" : ""}
              onClick={() => nav("/challenges/health")}
            >
              건강
              <span>
                {esgTotal?.health
                  ? esgTotal?.health
                  : eachTotal?.health
                  ? eachTotal?.health
                  : 0}
              </span>
            </li>
          </ul> */}
            <div className="top">
              <div className="main-wrapper">
                <div className="flex">
                  <h3>인증 NOW</h3>

                  {/* <Link>전체보기</Link> */}
                </div>

                <div className="post-wrapper">
                  {auth?.length ? (
                    <Swiper
                      modules={[Autoplay, Navigation, Pagination]}
                      spaceBetween={10}
                      slidesPerView={1}
                      centeredSlides={true}
                      // loop={true}
                      speed={600}
                      // autoplay={{ delay: 3500, disableOnInteraction: false }}
                      pagination={{ clickable: true }}
                      // loop={true}
                      onSlideChange={() => console.log("slide change")}
                      onSwiper={(swiper) => console.log(swiper)}
                    >
                      {auth?.length
                        ? auth.map((item, index) => (
                            <SwiperSlide
                              className="esg-card"
                              style={{
                                width: 320,
                                height: 260,
                                borderRadius: 10,
                              }}
                            >
                              <a
                                href={`/authlist/${item?.challengeId}#${item?.ID}`}
                                key={index}
                              >
                                <div
                                  className="card-img"
                                  style={{
                                    height: 144,
                                    backgroundImage: `url(${item?.thumbnail})`,
                                  }}
                                >
                                  <div className="top-menu">
                                    <div className="user-info">
                                      <div
                                        className="user-img"
                                        style={{
                                          backgroundImage: `url(${
                                            item?.authorAvatar ||
                                            DEFAULT_USER_IMAGE
                                          })`,
                                        }}
                                      ></div>
                                      <div>
                                        <p className="name">
                                          {item?.authorName}
                                        </p>
                                        <p className="date">{item?.date}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="under-menu">
                                  <div className="cate">
                                    <div className="icon-bar">
                                      <p>
                                        <img
                                          src="/images/icon-like.svg"
                                          alt=""
                                        />
                                        {item?.likeCount}
                                      </p>
                                      <p>
                                        <img
                                          src="/images/icon-kakao.svg"
                                          alt=""
                                        />
                                        {item?.commentCount}
                                      </p>
                                    </div>
                                    <div>
                                      <p className={`page-desc ${item?.slug} `}>
                                        {item.cate}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="title-box">
                                    <p className="page-title">
                                      # {item.challengeTitle}
                                    </p>
                                    {/* <p className="page-title"># {item.cate}</p> */}
                                  </div>
                                  <div className="Participation">
                                    <div className="user-list-box">
                                      {/* <div className="user-list"></div>
                                <div className="user-list"></div>
                                <div className="user-list"></div> */}
                                    </div>
                                    <div className="Participation-title">
                                      <h2>
                                        +
                                        {item?.participantNum
                                          ? item?.participantNum
                                          : 0}
                                        <span>명 참여중</span>
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </SwiperSlide>
                          ))
                        : null}
                    </Swiper>
                  ) : auth ? (
                    <div className="bg"></div>
                  ) : (
                    <div className="auth-null">
                      <img
                        onClick={() => nav("/challenges")}
                        src="/images/tp.png"
                        alt=""
                      />
                      <img className="pg" src="/images/pg.png" alt="" />
                    </div>
                  )}
                </div>
              </div>
              <div className="challenge-box">
                <a href="/alone">
                  <div className="challenge-card">
                    <p className="challenge-title">혼자하기 좋은 챌린지</p>
                    <div className="going">
                      <span>보러가기</span>
                      <img src="/images/going-arrow.svg" alt="" />
                    </div>
                  </div>
                </a>
                <a href="/colleague">
                  <div className="challenge-card Colleague">
                    <p className="challenge-title">동료와 함께하는 챌린지</p>
                    <div className="going">
                      <span>보러가기</span>
                      <img src="/images/going-arrow.svg" alt="" />
                    </div>
                  </div>
                </a>
                <a href="/family">
                  <div className="challenge-card family">
                    <p className="challenge-title">가족과 함께하는 챌린지</p>
                    <div className="going">
                      <span>보러가기</span>
                      <img src="/images/going-arrow.svg" alt="" />
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="bold-line"></div>
            <div className="popular-box">
              <h2 className="popular-title">인기 챌린지</h2>
              <div className="content-environment">
                <ul className="tab-menu">
                  <li
                    className={selectedTab === "" ? "active" : ""}
                    onClick={() => handleTabClick("")}
                  >
                    전체
                  </li>
                  <li
                    className={selectedTab === "eco" ? "active" : ""}
                    onClick={() => handleTabClick("eco")}
                  >
                    환경
                  </li>
                  <li
                    className={selectedTab === "culture" ? "active" : ""}
                    onClick={() => handleTabClick("culture")}
                  >
                    조직문화
                  </li>
                  <li
                    className={selectedTab === "health" ? "active" : ""}
                    onClick={() => handleTabClick("health")}
                  >
                    건강
                  </li>
                </ul>
                <div className="challenge-content-wrap">
                  {challenges?.length
                    ? challenges?.map((item, key) => (
                        <Link
                          to={`/challenge/${item?.ID}`}
                          className="challenge-content-box"
                        >
                          <div className="challenge-content">
                            <div
                              className="content-img"
                              style={{
                                backgroundImage: `url(${item?.thumbnail})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                              }}
                            >
                              <div className="noti">주 {item?.quota}회</div>
                            </div>
                            <div className="content-text-box">
                              <p className="categories">{item?.cate}</p>
                              <p className="content-title">{item?.postTitle}</p>
                              <div className="point-box">
                                <img src="/images/esg-point.svg" alt="" />
                                {item?.point}
                              </div>
                              {/* <p className="date">7.3 (월) - 7.16 (일)</p> */}
                            </div>
                          </div>
                        </Link>
                      ))
                    : null}
                </div>
              </div>
              {/* {paged < maxPaged ? (
                <div className="show-box">
                  <div
                    className="show-more"
                    onClick={() => setPaged((p) => p + 1)}
                  >
                    더보기
                    <img src="/images/esg-plus-icon.svg" alt="" />
                  </div>
                </div>
              ) : (
                <div style={{ height: "140px" }} />
              )} */}
            </div>
          </div>
        </Wrapper>
      </Layout>{" "}
      <Alert active={active} toggle={toggle} />
    </>
  );
};

export default Main;
