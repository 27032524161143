import React, { useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Wrapper } from "./styled";
import { GetMe } from "../../service/me";
import { useRecoilState } from "recoil";
import { userData } from "../../store/users";
import { useParams, useSearchParams } from "react-router-dom";

const Layout = ({ children, headerType, footerType, headerTitle }) => {
  // useEffect(() => {
  //   localStorage.setItem(
  //     "user_token",
  //     "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvd3AuZGFpdHNvLmtiZHMuY28ua3IiLCJpYXQiOjE2OTAwODgyNzAsIm5iZiI6MTY5MDA4ODI3MCwiZXhwIjoxNjkwNjkzMDcwLCJkYXRhIjp7InVzZXIiOnsiaWQiOjF9fX0.GLKKqYldWqSi2MgiJe4TjRXWwA8as1OEycDPH-xVbaM"
  //   );
  // }, []);
  // useEffect(() => {
  //   localStorage.setItem(
  //     "user_token",
  //     "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvd3AuZGFpdHNvLmtiZHMuY28ua3IiLCJpYXQiOjE2OTE5OTEwMjYsIm5iZiI6MTY5MTk5MTAyNiwiZXhwIjoxNjkyNTk1ODI2LCJkYXRhIjp7InVzZXIiOnsiaWQiOjF9fX0.AUuJGMo9T92GDz5YENvPjMBA4ri8789yw-ovHIl8xqk"
  //   );
  // }, []);

  const [searchParams, setSearchParams] = useSearchParams();
  const userToken = searchParams.get("userToken");
  const params = useParams();

  useEffect(() => {
    if (userToken) {
      localStorage.setItem("user_token", userToken);
    }
    // localStorage.setItem(
    //   "user_token",
    //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvd3AuZGFpdHNvLmtiZHMuY28ua3IiLCJpYXQiOjE2OTUyNjc1ODIsIm5iZiI6MTY5NTI2NzU4MiwiZXhwIjoxNjk1ODcyMzgyLCJkYXRhIjp7InVzZXIiOnsiaWQiOjkxOX19fQ.th4ehFITOVy6PQ-4k0HS-o25lm7gD-6N_NAlNViobpg"
    // );
  }, [userToken]);

  // useEffect(() => {
  //   const handleReceiveMessage = (event) => {
  //     console.log("Data received in iframe:", event.data);
  //     console.log("Data received in iframe:", typeof event.data);

  //     if (typeof event?.data == "string") {
  //       localStorage.setItem("user_token", event?.data);
  //       console.log("Data received in iframe:", event.data);
  //       console.log("Data received in iframe:", typeof event.data);
  //       // alert(event?.data);
  //     }
  //   };

  //   window.addEventListener("message", handleReceiveMessage);

  //   return () => {
  //     window.removeEventListener("message", handleReceiveMessage);
  //   };
  // }, []);

  const [user, setUser] = useRecoilState(userData);

  useEffect(() => {
    GetMe()
      .then((res) => {
        console.log(res);
        if (res.data.code == "200") {
          setUser(res.data.body);
        } else {
          setUser({});
          localStorage.clear();
        }
      })
      .catch((res) => {
        console.log(res);
        setUser({});
        localStorage.clear();
      });
  }, [searchParams]);

  console.log(searchParams.get("paged"));

  return (
    <Wrapper>
      <Header type={headerType} headerTitle={headerTitle} />
      {children}
      <Footer type={footerType} />
    </Wrapper>
  );
};

export default Layout;
